import clsx from 'clsx';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { isDesktopScreenSize } from 'utils/screen-size';
import FilterContentSkeleton from './FilterContent/FilterContentSkeleton';

const FilterContent = dynamic(() => import('./FilterContent/FilterContent'), {
  ssr: false,
  loading: () => <FilterContentSkeleton />,
});

const FilterPanel: FC = () => {
  const { data, hasFetchedCategoryFilters, handleFilterChange, handleClearAllFilters } =
    useDynamicCatalogContext();
  const { isSearchCrawler } = useStaticCatalogContext();
  const { filters } = data;

  // in case of search crawler, we need to render the filter panel on server side
  // which is isDesktopScreenSize not working
  if (!isSearchCrawler && !isDesktopScreenSize()) {
    return null;
  }

  if (!hasFetchedCategoryFilters) {
    return <FilterContentSkeleton />;
  }

  return (
    <FilterContent
      className={clsx(
        'hidden h-max max-h-max min-h-0 w-[320px] rounded-2xl p-5 pt-8',
        'desktop:flex desktop:basis-auto desktop:flex-col desktop:gap-4',
      )}
      filters={filters}
      onFilterChange={handleFilterChange}
      onClearAllFilter={handleClearAllFilters}
    />
  );
};

export default FilterPanel;
