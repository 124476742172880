import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';
import { TestIdsSegmentMenu } from 'constants/e2eIds/segment-menu-test-ids';

const SegmentMenuSkeleton: FC = () => {
  return (
    <div
      data-test-id={TestIdsSegmentMenu.SKELETON}
      className="sticky inset-0 top-12 z-10 flex h-[39px] animate-pulse items-center justify-around overflow-hidden bg-white"
    >
      <SkeletonItem
        width="w-24"
        className="mx-4 my-3 leading-5"
        repeat={7}
      />
    </div>
  );
};

export default SegmentMenuSkeleton;
