import { ZDTProduct } from '@zalora/doraemon-ts';
import { PageInfo } from 'utils/catalog/catalog-page-type';

interface MenuCategory {
  Label: string;
  Value: string;
  ResultCount: number;
  Selected: boolean;
  SubOptions: null;
  CategoryUrlKey: string;
}

type SLUG_KEYS = 'c' | 'scat' | 'b';

const SLUG_TYPES = {
  categoryId: 'categoryId',
  subCategoryId: 'subCategoryId',
  brandId: 'brandId',
} as const;

const MAPPING_KEYS: Record<string, SLUG_KEYS> = {
  categoryId: 'c',
  subCategoryId: 'scat',
  brandId: 'b',
} as const;

/*
  Example:
  - url has brand slug: 
    > "/c/women/clothing/nike/c-3/scat-100/b-99"
    > "/c/women/clothing/dresses/nike/s-3/scat-150/b-99" 
  - url doesn't have brand slug: 
    > "/c/women/clothing/c-3/scat-100"
    > "/c/women/clothing/dresses/s-3/scat-150"
*/

export const buildSubCategoryUrl = ({
  categoryItem,
  pageInfo,
  pathName,
  queryParams,
}: {
  categoryItem: MenuCategory | ZDTProduct.FilterOptions;
  pageInfo: PageInfo;
  pathName: string;
  queryParams?: string;
}) => {
  // Remove `c` in slugs
  // slug: = ['segment`, `category`,`subCategory`,`brand`,'specialKey` ]
  const slugs = pathName
    .replace(/^\/c\//, '')
    .trim()
    .split('/') as string[];
  const mappingSlugAndId: Partial<Record<SLUG_KEYS, string>> = {};
  const { segment, categoryId, subCategoryId, brandId, specialPage } = pageInfo;
  const categoryItemValue = categoryItem.Value || undefined;
  const categoryItemCategoryUrlKey = categoryItem.CategoryUrlKey || undefined;

  let url = `/c/${segment}`;

  if (!categoryId || categoryId === categoryItemValue) {
    url = `${url}/${categoryItemCategoryUrlKey}`;
    mappingSlugAndId.c = categoryItemValue;
  } else {
    url = `${url}/${slugs[1]}`;
    mappingSlugAndId.c = categoryId;

    if (categoryId && categoryId !== categoryItemValue) {
      url = `${url}/${categoryItemCategoryUrlKey}`;
      mappingSlugAndId.scat = categoryItemValue;
    }
  }

  if (brandId) {
    const positionOfBrandSlug = Number(!!segment) + Number(!!categoryId) + Number(!!subCategoryId);

    url = `${url}/${slugs[positionOfBrandSlug]}`;
    mappingSlugAndId.b = brandId;
  }

  if (specialPage) {
    url = `${url}/${specialPage}`;
  }

  Object.keys(SLUG_TYPES).forEach((slug) => {
    const slugKey = MAPPING_KEYS[slug];
    const value = mappingSlugAndId[slugKey];

    if (value) {
      url = `${url}/${slugKey}-${value}`;
    }
  });

  if (queryParams) {
    url += `?${queryParams}`;
  }

  return url;
};

/**
 * When the url do not include any category info
 * - /c/men
 * - /c/men/nike/b-126
 * - /c/men/all
 * The category will be injected into pathname
 * - /c/men/clothing/c-26
 * - /c/men/nike/clothing/c-26/b-126
 * - /c/men/clothing/all/c-26
 */
export const buildCategoryUrl = ({
  pageInfo,
  category,
  pathName,
  queryParams,
}: {
  pageInfo: PageInfo;
  category: ZDTProduct.FilterOptions;
  pathName: string;
  queryParams?: string;
}) => {
  // Remove `c` in slugs
  // slug: = ['segment`, `category`,`subCategory`,`brand`,'specialKey` ]
  const [, ...slugs] = pathName.trim().split('/') as string[];
  const { segment, brandId, specialPage } = pageInfo;

  let slugPathname = '';
  let idPathname = '';

  if (segment) {
    slugPathname += `/${segment}`;
  }

  // Add root category
  slugPathname += `/${category.CategoryUrlKey}`;
  idPathname += `/c-${category.Value}`;

  if (brandId) {
    const positionOfBrandSlug = Number(!!segment) + 1;

    slugPathname += `/${slugs[positionOfBrandSlug]}`;
    idPathname += `/b-${brandId}`;
  }

  if (specialPage) {
    slugPathname += `/${specialPage}`;
  }

  let url = `/c${slugPathname}${idPathname}`;

  if (queryParams) {
    url += `?${queryParams}`;
  }

  return url;
};
