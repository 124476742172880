import { Pagination as ZUIPagination } from '@zalora/zui';
import { useTranslation } from 'next-i18next';
import { ComponentProps, FC } from 'react';
import { LinkPagination } from './LinkPagination';

interface Props extends Omit<ComponentProps<typeof ZUIPagination>, 'labels' | 'aria'> {
  variant?: 'default' | 'link';
}

const Pagination: FC<Props> = ({ variant = 'default', ...paginationProps }) => {
  const { t } = useTranslation();

  if (variant === 'link') {
    return <LinkPagination {...paginationProps} />;
  }

  return (
    <ZUIPagination
      aria={{
        paginationLabel: t('Pagination controls'),
        nextButtonLabel: t('Navigate to the next page'),
        previousButtonLabel: t('Navigate to the previous page'),
      }}
      labels={{
        nextButton: t('Next'),
        previousButton: t('Previous'),
      }}
      {...paginationProps}
    />
  );
};

export default Pagination;
