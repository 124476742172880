import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, memo, useMemo } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import { getInternalLinkOptions, getInternalLinkTitle } from './utils';

const InternalLinks: FC = () => {
  const router = useRouter();
  const { t } = useTranslation('catalog');
  const { pageInfo, seoInfo } = useStaticCatalogContext();
  const { menuSegments, menuCategories, categoryFilter } = useDynamicCatalogContext();
  const pageName = useMemo(() => getInternalLinkTitle(seoInfo), [seoInfo]);

  const options = useMemo(
    () =>
      getInternalLinkOptions({
        pageInfo,
        menuSegments,
        menuCategories,
        categoryFilter,
        asPath: router.asPath,
      }),
    [categoryFilter, menuCategories, menuSegments, pageInfo, router.asPath],
  );

  if (!options?.length) {
    return null;
  }

  const title = t('More in {{name}}', { name: pageName });

  return (
    <section className="my-4 space-y-4 rounded-2xl bg-grey-10 p-4 text-center tablet:my-8 desktop:my-6">
      {pageName ? <h3 className="text-sm font-bold">{title}</h3> : null}

      <div className="mx-auto flex max-w-[577px] flex-wrap justify-center">
        {options.map((item) => (
          <Link
            href={item.url}
            key={item.url}
            data-test-id={TestIdsCatalog.INTERNAL_LINK_ITEM}
            className="px-4 py-2 text-sm"
          >
            {item.label}
          </Link>
        ))}
      </div>
    </section>
  );
};

export default memo(InternalLinks);
