import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { ModalDialogVariant } from '@zalora/zui';
import { OfficialStoreIcon, VariationsIcon, ZaloraVipIcon } from '@zalora/zui-icons';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import { SyntheticEvent, forwardRef, lazy, useCallback, useMemo } from 'react';
import ProductRating from 'components/ProductRating';
import WishlistButton from 'components/WishlistButton';
import { MAX_TIMES_SHOW_VARIATION_TOOLTIP } from 'constants/catalog';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import withIntersectionObserver from 'hocs/withIntersectionObserver';
import useCatalogScarcityProducts from 'hooks/api/scarcity/useCatalogScarcityProducts';
import { usePromotion } from 'hooks/api/usePromotion';
import useSocialProofing from 'hooks/useSocialProofing';
import { SegmentData } from 'pages/catalog/types';
import { useCatalogDispatch, useCatalogStore } from 'stores/catalog';
import * as catalogSelectors from 'stores/catalog/selectors';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';
import { useUiDispatch } from 'stores/ui';
import { getCountVariationsOpenedTimes, localStorageKeys } from 'utils/local-storage';
import * as productUtils from 'utils/product';
import { captureError } from 'utils/raven';
import { getCatalogSocialProofingDataBySku } from 'utils/socialProofing';
import ProductItemPrice from '../../ProductItemPrice';
import { VariationsTooltip } from '../Variants/VariationsTooltip';
import CatalogProductAdLabel from './CatalogProductAdLabel';
import CatalogProductImage from './CatalogProductImage';
import CatalogProductMetaLabel from './CatalogProductMetaLabel';
import CatalogProductOverlay from './CatalogProductOverlay';
import CatalogProductSocialProofingDetails from './CatalogProductSocialProofingDetails';
import ProductLinkWrapper from './ProductLinkWrapper';

const CatalogVariantsModal = lazy(() => import('../Variants/CatalogVariantsModal'));

interface Props {
  order: number;
  product: ZDTCatalog.Product | ZDTProduct.Product;
  index: number;
  segmentData: SegmentData;
  isImpressed?: boolean;
}

const CatalogProduct = forwardRef<HTMLDivElement, Props>(
  ({ order, product, index, segmentData, isImpressed }, ref) => {
    const { IsMembershipEligible, OfficialstoreInformation, Variations, ImageList } = product;

    const uiDispatch = useUiDispatch();
    const router = useRouter();

    const { appliedFilters, configSkus } = useDynamicCatalogContext();
    //TODO: create a useCatalogPromotion, which receives no input and get configSkus from catalog store after we move `products` to store
    const { data: promotions } = usePromotion(configSkus);
    const { sourceCatalog, listId } = useStaticCatalogContext();
    const catalogDispatch = useCatalogDispatch();
    const skuFirstProductHasVariations = useCatalogStore(
      catalogSelectors.skuFirstProductHasVariations,
    );

    const configSku = product.ConfigSku || '';
    const { isAllowedToShow } = useSocialProofing(configSku);
    // Fetch & re-use the data from swr state by using all the configSkus available in the catalog page
    const { data: scarcities } = useCatalogScarcityProducts();
    const isSocialProofingEnabled = useOptimizelyStore(optimizelySelector.isSocialProofingEnabled);

    const productAdId = product.AdId || undefined;
    const productBrand = productUtils.getBrandName(product);
    const productConfigSku = productUtils.getConfigSku(product);
    const productMainImageUrl = productUtils.getMainImageURL(product);
    const productName = productUtils.getName(product);
    const productUrl = productUtils.getURL(product);

    const shouldShowVariantTooltip = useCatalogStore(catalogSelectors.shouldShowVariantTooltip);
    const isFirstProductHasVariations = productConfigSku === skuFirstProductHasVariations;

    const isOfficialStore = !!OfficialstoreInformation?.Label;
    const hoverImage = ImageList?.[1];

    const onWishlistToggle = useCallback(
      (isAdded: boolean) => {
        if (isAdded) {
          const socialProofingData = getCatalogSocialProofingDataBySku(configSku, scarcities);

          import('hooks/api/useWishlist.tracking')
            .then(({ trackingOnProductAddedToWishlist }) => {
              trackingOnProductAddedToWishlist({
                configSku: productConfigSku,
                sourceCatalog,
                listId,
                promotions,
                socialProofingData,
              });
            })
            .catch((error) => {
              captureError('Error when tracking on product added to wishlist', {
                error,
                tag: 'get-request',
              });
            });
        } else {
          import('hooks/api/useWishlist.tracking')
            .then(({ trackingOnProductRemoveFromWishlist }) => {
              trackingOnProductRemoveFromWishlist({
                configSku: productConfigSku,
                pagination: Number(router.query.page || 1),
                sourceCatalog,
                listId,
                promotions,
              });
            })
            .catch((error) => {
              captureError('Error when tracking on product remove from wishlist', {
                error,
                tag: 'get-request',
              });
            });
        }
      },
      [
        productConfigSku,
        sourceCatalog,
        listId,
        promotions,
        scarcities,
        router.query.page,
        configSku,
      ],
    );

    const wishlistButton = useMemo(() => {
      return isImpressed && productConfigSku ? (
        <WishlistButton
          key="wishlist"
          className="m-0 !h-5 !w-7 bg-transparent p-0"
          activeIconClassName="fill-red-60"
          sku={productConfigSku}
          onToggle={onWishlistToggle}
        />
      ) : null;
    }, [isImpressed, productConfigSku, onWishlistToggle]);

    const onClick = (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (shouldShowVariantTooltip) {
        const tooltipCount = getCountVariationsOpenedTimes() + 1;

        if (tooltipCount >= MAX_TIMES_SHOW_VARIATION_TOOLTIP) {
          catalogDispatch.showVariantTooltip(false);
        }

        localStorage.setItem(localStorageKeys.VARIATIONS_TOOLTIP_COUNT, tooltipCount.toString());
      }

      uiDispatch.showModal({
        key: 'catalog-variants-modal',
        config: { modalProps: { variant: ModalDialogVariant.RIGHT_MODAL } },
        ModalDialogPage: () => <CatalogVariantsModal product={product} />,
      });
    };

    const params = useMemo(
      () => ({
        ad_id: productAdId,
        catalogType: segmentData.catalogType,
        listId: segmentData.listId,
      }),
      [productAdId, segmentData],
    );

    const styles = useMemo(
      () => ({ order, contentVisibility: 'auto', containIntrinsicHeight: 370 }),
      [order],
    );

    const onProductClick = useCallback(() => {
      const socialProofingData = getCatalogSocialProofingDataBySku(configSku, scarcities);

      import('pages/catalog/catalog.tracking')
        .then(({ trackingOnClickProductInCatalog }) => {
          trackingOnClickProductInCatalog({
            index,
            product,
            segmentData,
            appliedFilters,
            sourceCatalog,
            listId,
            promotions,
            socialProofingData,
          });
        })
        .catch((error) => {
          captureError('Error when tracking on click product in catalog', {
            error,
            tag: 'get-request',
          });
        });
    }, [
      appliedFilters,
      configSku,
      promotions,
      index,
      listId,
      product,
      scarcities,
      segmentData,
      sourceCatalog,
    ]);

    return (
      <ProductLinkWrapper
        productUrl={productUrl}
        params={params}
        className="relative"
        onClick={onProductClick}
        //containIntrinsicHeight is not available for TS type
        //@ts-ignore
        style={styles}
        data-sku={productConfigSku} /* Legacy */
        data-config-sku={productConfigSku}
        data-test-id={TestIdsCatalog.PRODUCT_LINK}
        data-ad={productAdId ? 'true' : undefined}
      >
        <div
          ref={ref}
          className="flex flex-col"
        >
          {/* Product image & icons */}
          <div
            className="relative overflow-hidden rounded-lg"
            data-test-id={TestIdsCatalog.PRODUCT_IMAGE}
          >
            <CatalogProductImage
              src={productMainImageUrl}
              hoverSrc={hoverImage || ''}
              alt={productName}
              index={index}
            />

            {/* Top-right icons */}
            <div className="absolute right-2 top-2 flex flex-col items-end gap-2">
              {product.AdId ? (
                <CatalogProductAdLabel />
              ) : (
                <CatalogProductMetaLabel product={product} />
              )}

              {Variations && Variations.length > 1 ? (
                <div className="flex">
                  {isFirstProductHasVariations ? (
                    <VariationsTooltip shouldShowTooltip={!!shouldShowVariantTooltip} />
                  ) : null}

                  <div onClick={onClick}>
                    <VariationsIcon className="!h-9 !w-9" />
                  </div>
                </div>
              ) : null}
            </div>

            {/* Rating & Reviews */}
            {product.ReviewStatistics?.AvgRating ? (
              <div className="absolute left-2 top-2 flex flex-col items-end gap-2">
                <ProductRating product={product} />
              </div>
            ) : null}

            {/* Promotion */}
            <CatalogProductOverlay
              product={product}
              promotions={promotions}
              className="absolute inset-x-0 bottom-0"
            />
          </div>

          {/* Product meta */}
          <div className="mt-1 flex grow flex-col p-1">
            <div className="flex items-center justify-between">
              <div className="flex flex-1 items-center truncate">
                <span
                  className="shrink-[10] truncate text-base font-bold"
                  title={productBrand}
                  data-test-id="productBrandName"
                >
                  {productBrand}
                </span>

                {isOfficialStore ? (
                  <OfficialStoreIcon
                    className="official-store-icon ml-1 !h-3 !w-3"
                    data-test-id="officialStore"
                  />
                ) : null}

                {IsMembershipEligible ? (
                  <ZaloraVipIcon
                    className="vip-icon ml-1 mt-0.5 h-2.5 w-5"
                    data-test-id="vip"
                  />
                ) : null}
              </div>

              {wishlistButton}
            </div>

            <div
              className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
              data-test-id="productTitle"
            >
              {productName}
            </div>
            <ProductItemPrice
              product={product}
              promotions={promotions}
              isLoading={promotions === undefined}
            />

            {isSocialProofingEnabled ? (
              <CatalogProductSocialProofingDetails
                configSku={configSku}
                isAllowedToShow={isAllowedToShow}
              />
            ) : null}
          </div>
        </div>
      </ProductLinkWrapper>
    );
  },
);

export default withIntersectionObserver(CatalogProduct, true);
