import { FC, memo } from 'react';
import { FooterLink } from 'components/Layout/DesktopLayout/DesktopFooter/DarkFooter/types';

interface Props {
  links: FooterLink[];
}

const DarkFooterLink: FC<Props> = ({ links }) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <>
      {links.map(({ url, label, rel }) => (
        <a
          key={label}
          href={url}
          rel={rel}
          title={label}
        />
      ))}
    </>
  );
};

export default memo(DarkFooterLink);
