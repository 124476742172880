import { ZDTSEO, ZDTAds } from '@zalora/doraemon-ts';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  createContext,
  FC,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { QueryParams } from 'api/APIClient';
import { BreadcrumbItemType } from 'components/Breadcrumbs';
import { getCatalogBreadcrumbs } from 'utils/catalog/breadcrumbs';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import { getCatalogParams } from 'utils/catalog/query';

const DEFAULT_SEO_INFO: ZDTSEO.CatalogInfo = {
  Brands: null,
  CanonicalUrl: '',
  H1: null,
  HreflangTag: '',
  MetaRobots: '',
  PageDescription: '',
  PageInfo: { CategoryBreadcrumbs: [], Brand: null, Segment: null },
  PageTitle: '',
  SeoText: '',
};

interface CatalogContextValue {
  catalogAds: Nullishable<{ CatalogBanners: ZDTAds.Banner[] }>;
  breadcrumbs: BreadcrumbItemType[];
  cmsBanner: Nullishable<ZDTAds.Banner>;
  listId?: string;
  pageInfo: PageInfo;
  seoInfo: ZDTSEO.CatalogInfo;
  sourceCatalog?: string;
  isSearchCrawler: boolean;
  params: QueryParams;
  impressedProducts?: MutableRefObject<string[]>;
  isEnrichAttributeEnabled: boolean;
}

interface Props extends PropsWithChildren {
  catalogAds?: { CatalogBanners: ZDTAds.Banner[] };
  pageInfo: PageInfo;
  seoInfo?: ZDTSEO.CatalogInfo;
  cmsBanner?: ZDTAds.Banner;
  sourceCatalog?: string;
  listId?: string;
  isSearchCrawler?: boolean;
  isEnrichAttributeEnabled?: boolean;
}

const StaticCatalogContext = createContext<CatalogContextValue>({} as never);

export const useStaticCatalogContext = () => useContext(StaticCatalogContext);

export const StaticCatalogContextProvider: FC<Props> = ({
  catalogAds,
  pageInfo,
  seoInfo = DEFAULT_SEO_INFO,
  cmsBanner,
  sourceCatalog,
  listId,
  children,
  isSearchCrawler = false,
  isEnrichAttributeEnabled = false,
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const pathName = router.asPath.split('?')[0];
  const impressedProducts = useRef<string[]>([]);

  const params = useMemo(
    () => getCatalogParams(router.query as QueryParams, pageInfo, isEnrichAttributeEnabled),
    [pageInfo, router.query, isEnrichAttributeEnabled],
  );

  const breadcrumbs = useMemo(
    () => getCatalogBreadcrumbs(seoInfo, params, t),
    [seoInfo, params, t],
  );

  useEffect(() => {
    // Clear impressed products when navigating to a new page
    // this helps to prevent tracking the same product multiple times when filtering or sorting
    return () => {
      impressedProducts.current = [];
    };
  }, [pathName]);

  const contextValue: CatalogContextValue = {
    catalogAds,
    breadcrumbs,
    cmsBanner,
    listId,
    pageInfo,
    params,
    seoInfo,
    sourceCatalog,
    isSearchCrawler,
    impressedProducts,
    // if this value is `true`, we shouldn't include `zeroSearchResultSuggestion_True` in query param
    isEnrichAttributeEnabled,
  };

  return (
    <StaticCatalogContext.Provider value={contextValue}>{children}</StaticCatalogContext.Provider>
  );
};
