/**
 * @param {string} catalogUrl - The catalog page url
 * This function will return the redirection link for the catalog page
 * It will return the catalogUrl without the `page` param
 */
export const getCatalogRedirectionLink = (catalogUrl: string) => {
  const [pathName, query] = catalogUrl.split('?');
  const updatedQuery = new URLSearchParams(query);

  updatedQuery.delete('page');
  const updatedQueryAsString = updatedQuery.toString();
  // workaround because type of `size` of `URLSearchParams` is not supported
  const updatedUrl = updatedQueryAsString.length
    ? `${pathName}?${updatedQueryAsString}`
    : `${pathName}`;

  return updatedUrl;
};
