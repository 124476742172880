import Link from 'next/link';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { getProductUrlFromSlug } from 'utils/url';

interface Props extends PropsWithChildren, HTMLAttributes<HTMLAnchorElement> {
  productUrl: string;
  params?: Record<string, string | undefined>;
}

const ProductLinkWrapper: FC<Props> = ({ children, productUrl, params, ...aProps }) => {
  const pathname = getProductUrlFromSlug(productUrl);

  return (
    <Link
      href={{
        pathname,
        query: params,
      }}
      as={pathname}
      {...aProps}
    >
      {children}
    </Link>
  );
};

export default ProductLinkWrapper;
