import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton/SkeletonItem';

const FilterBarSkeleton: FC = () => {
  return (
    <div className="flex animate-pulse gap-4 border-y border-b-grey-20 py-2 pl-4 tablet:justify-center">
      <SkeletonItem
        width="w-20"
        height="h-8"
        repeat={4}
      />
    </div>
  );
};

export default FilterBarSkeleton;
