import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { FC, memo } from 'react';
import { Promotions } from 'stores/catalog/types';
import * as productUtils from 'utils/product';

interface Props {
  product: ZDTCatalog.Product | ZDTProduct.Product;
  className?: string;
  promotions: Promotions;
}

const CatalogProductOverlay: FC<Props> = ({ product, className, promotions }) => {
  const productConfigSku = productUtils.getConfigSku(product);
  const promotion = promotions?.[productConfigSku]?.[0];

  if (!promotion) {
    return null;
  }

  return (
    <div className={clsx('bg-black px-2 py-1 text-center text-white', className)}>
      <div className="truncate text-xxs font-bold tablet:text-xs">{promotion.CodeText}</div>
      <div className="truncate text-xxxs leading-3 tablet:text-xxs">
        {promotion.DescriptionText}
      </div>
    </div>
  );
};

export default memo(CatalogProductOverlay);
