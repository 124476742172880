import { useMemo } from 'react';
import { QueryParams } from 'api/APIClient';
import { CatalogFilterName } from 'constants/catalog';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import { getFilterByType } from 'utils/catalog/filters';
import { getCategoryFilterParams } from 'utils/filters/categoryFilter';
import useCatalogFilters from './useCatalogFilters';

const useCategoryFilters = ({
  params = {},
  pageInfo,
  shouldFetch,
  isSearchCrawler,
}: {
  params: QueryParams;
  pageInfo: PageInfo;
  shouldFetch?: boolean;
  isSearchCrawler: boolean;
}) => {
  const categoryFilterParams: QueryParams = useMemo(
    () => getCategoryFilterParams(params, pageInfo),
    [params, pageInfo],
  );

  const { filters, hasFetched, isLoading, error } = useCatalogFilters({
    params: categoryFilterParams,
    pageInfo,
    shouldFetch,
    isSearchCrawler,
  });

  const categoryFilter = useMemo(
    () => (filters ? getFilterByType(filters, CatalogFilterName.CATEGORY) : undefined),
    [filters],
  );

  return {
    categoryFilter,
    hasFetched,
    isLoading,
    menuCategories: filters?.MenuCategories,
    menuSegments: filters?.MenuSegments,
    error,
  };
};

export default useCategoryFilters;
