import AppSmartBannerContentSkeleton from './AppSmartBannerContentSkeleton';

const AppSmartBannerSkeleton = () => {
  return (
    <div className="mb-2 flex h-16 items-center gap-1 p-2 desktop:hidden">
      <div className="min-h-5 min-w-5 rounded bg-grey-20" />
      <div className="mr-1 min-h-6 min-w-6 rounded bg-grey-20" />

      <AppSmartBannerContentSkeleton />
    </div>
  );
};

export default AppSmartBannerSkeleton;
