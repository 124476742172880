import { Button } from '@zalora/zui';
import { SortIcon } from '@zalora/zui-icons';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, lazy, useMemo } from 'react';
import { ErrorBanner } from 'components/Banner/ErrorBanner/ErrorBanner';
import Loading from 'components/Loading';
import MembershipPurchaseBannerSkeleton from 'components/MembershipPurchaseBanner/MembershipPurchaseBannerSkeleton';
import { NoResults } from 'components/NoResults';
import { CatalogFilterName } from 'constants/catalog';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import useUser from 'hooks/api/useUser';
import { useCatalogRedirectIfNeeded } from 'hooks/catalog/useCatalogRedirectIfNeeded';
import { useUiDispatch } from 'stores/ui';
import { shouldShowBreadcrumbs } from 'utils/catalog/breadcrumbs';
import { isZISPageInfo } from 'utils/catalog/catalog-page-type';
import { getFilterByType, shouldShowZaloraVipFilter } from 'utils/catalog/filters';
import CatalogPagination from './CatalogPagination';
import CatalogProducts from './CatalogProducts';
import { CatalogSkeleton } from './CatalogSkeleton';
import CatalogTitle from './CatalogTitle/CatalogTitle';
import FilterPanel from './Filter/FilterPanel';
import InternalLinks from './InternalLinks/InternalLinks';
import { MoreStores } from './MoreStores';
import SortModalConfig from './Sort/SortModal.config';
import TopNav from './TopNav/TopNavWrapper';
import ZaloraVipButton from './TopNav/ZaloraVipFilterButton';
import withCatalogTracking from './withCatalogTracking';

const SortModal = lazy(() => import('./Sort/SortModal'));

const MembershipPurchaseBanner = dynamic(
  () => import('components/MembershipPurchaseBanner/MembershipPurchaseBannerCatalog'),
  {
    ssr: false,
    loading: () => <MembershipPurchaseBannerSkeleton />,
  },
);

interface Props {
  className?: string;
}

const Catalog: FC<Props> = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const uiDispatch = useUiDispatch();
  const { data, error, handleFilterChange, isLoadingProducts } = useDynamicCatalogContext();
  const { pageInfo, params } = useStaticCatalogContext();
  const { data: user, isLoading: isUserLoading } = useUser();
  const shouldShowMembershipPurchaseBanner = !isZISPageInfo(pageInfo);

  const { products, filters, sponsoredProducts } = data;
  const { NumProductFound } = products || {};

  const hasNoResults = NumProductFound === 0;

  useCatalogRedirectIfNeeded();

  const onClickSort = () => {
    uiDispatch.showModal({
      key: 'sort-modal',
      config: SortModalConfig,
      ModalDialogPage: () => <SortModal />,
    });
  };

  const segmentData = useMemo(
    () => ({
      segment: params.segment as string,
      categoryId: params.categoryId as string,
      currentPage: Number(router.query.page || 1),
      params,
      vendor: products?.Vendor,
    }),
    [params, products?.Vendor, router.query.page],
  );

  if (error) {
    // Render empty page if any error, do not apply redirect to segment page here,
    // because it could cause non-indexable on GSC
    return <NoResults />;
  }

  if (isLoadingProducts && (!sponsoredProducts || !products)) {
    return <CatalogSkeleton shouldHideBreadcrumbs={!shouldShowBreadcrumbs(pageInfo)} />;
  }

  if (hasNoResults) {
    return <NoResults />;
  }

  const zaloraVipFilter = filters
    ? getFilterByType(filters, CatalogFilterName.MEMBERSHIP_ELIGIBLE)
    : undefined;

  /* Catalog is also used in ZIS pages, it has its own banner */

  return (
    <>
      <TopNav />
      <ErrorBanner />

      {/* Catalog content */}
      <div className="flex flex-col gap-5 p-4 desktop:flex-row desktop:p-0">
        <FilterPanel />

        <div className="w-full flex-1">
          <MoreStores storeEntry={products?.StoreEntry} />

          <div className="flex flex-col gap-6">
            <div className="flex items-center justify-between gap-4">
              <CatalogTitle />

              <div className="hidden gap-4 desktop:flex">
                {shouldShowZaloraVipFilter(zaloraVipFilter) ? (
                  <ZaloraVipButton
                    filter={zaloraVipFilter}
                    onChange={handleFilterChange}
                  />
                ) : null}

                <Button
                  aria-label="Sort"
                  variant="outline"
                  size="sm"
                  className="inline-flex h-8 rounded-lg px-3 py-1"
                  onClick={onClickSort}
                  data-test-id={TestIdsCatalog.SORT_FILTER_BUTTON}
                >
                  {t('Sort')}
                  <SortIcon />
                </Button>
              </div>
            </div>

            {shouldShowMembershipPurchaseBanner && (isUserLoading || user) ? (
              <MembershipPurchaseBanner />
            ) : null}

            <CatalogProducts segmentData={segmentData} />
          </div>

          <CatalogPagination />

          <InternalLinks key={router.asPath} />
        </div>
      </div>

      {isLoadingProducts ? <Loading isFullScreen /> : null}
    </>
  );
};

export default withCatalogTracking(Catalog);
