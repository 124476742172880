import { FC, PropsWithChildren, useEffect, useRef, ComponentType } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const DEFAULT_THRESHOLD = 0.75;

export type OnImpression = (el: any) => void;

export type IntersectionObserverWrapperProps = PropsWithChildren<
  {
    onImpression?: OnImpression;
    options?: {
      threshold?: number;
      rootMargin?: string;
    };
    trackingData?: any;
    conditionInvokeCallback?: boolean;
  } & React.HTMLProps<HTMLDivElement>
>;

const withIntersectionObserver = <T,>(
  Component: ComponentType<IntersectionObserverWrapperProps & T>,
  shouldPassImpressionState = false,
) => {
  const IntersectionObserverWrapper: FC<IntersectionObserverWrapperProps & T> = ({
    onImpression,
    options,
    trackingData,
    conditionInvokeCallback = true,
    ...props
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { threshold = DEFAULT_THRESHOLD, rootMargin } = options || {};

    const entry = useIntersectionObserver(ref, {
      threshold,
      rootMargin,
      freezeOnceVisible: true,
    });
    const isVisible = !!entry?.isIntersecting;

    useEffect(() => {
      if (conditionInvokeCallback && isVisible && onImpression) {
        onImpression(trackingData);
      }
    }, [conditionInvokeCallback, isVisible]);

    return (
      <Component
        {...(props as T & IntersectionObserverWrapperProps)}
        {...(shouldPassImpressionState ? { isImpressed: isVisible } : {})}
        ref={ref}
      />
    );
  };

  return IntersectionObserverWrapper;
};

export default withIntersectionObserver;
