import clsx from 'clsx';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, memo } from 'react';
import { NUMBER_OF_ITEMS_PLACEHOLDER } from 'constants/catalog';
import { isZSRData } from 'utils/catalog/zsr-suggestions';
import H1Content from './H1Content';
import ZSRSkeleton from './ZSRSuggestion/ZSRSkeleton';

const ZSRSuggestions = dynamic(() => import('./ZSRSuggestion/ZSRSuggestionsWrapper'), {
  ssr: false,
  loading: () => <ZSRSkeleton />,
});

interface Props {
  className?: string;
}

const CatalogTitle: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const { data } = useDynamicCatalogContext();
  const { seoInfo, pageInfo } = useStaticCatalogContext();
  const { products } = data;
  const { NumProductFound, ZeroSearchResults } = products || {};

  const originalSearchQuery = ZeroSearchResults?.OriginalSearchQuery || '';
  const suggestedSearchQuery = ZeroSearchResults?.SuggestedSearchQuery || '';
  const otherSuggestions = ZeroSearchResults?.Suggestions || [];

  const shouldShowZSRSuggestions = isZSRData(ZeroSearchResults);
  const hasCountInH1 = seoInfo.H1?.includes(NUMBER_OF_ITEMS_PLACEHOLDER) || false;
  const numberOfProductFound = NumProductFound || 0;

  return (
    <div
      id="section__page-title"
      className={clsx(
        'flex flex-1 items-center justify-between gap-4',
        'min-w-0 scroll-mt-36',
        'tablet:justify-center',
        'desktop:scroll-mt-24 desktop:justify-start',
        className,
      )}
    >
      {shouldShowZSRSuggestions ? (
        <ZSRSuggestions
          originalSearchQuery={originalSearchQuery}
          suggestedSearchQuery={suggestedSearchQuery}
          otherSuggestions={otherSuggestions}
        />
      ) : (
        <H1Content
          pageInfo={pageInfo}
          seoInfo={seoInfo}
          numOfProduct={numberOfProductFound}
          query={typeof query.q === 'string' ? query.q : ''}
        />
      )}

      {!hasCountInH1 && products ? (
        <span
          className={clsx(
            'whitespace-nowrap text-xs text-grey-80',
            'tablet:text-sm tablet:text-grey-60',
            'desktop:mt-1 desktop:text-base',
          )}
        >
          {numberOfProductFound.toLocaleString()} {t(numberOfProductFound > 1 ? 'items' : 'item')}
        </span>
      ) : null}
    </div>
  );
};

export default memo(CatalogTitle);
