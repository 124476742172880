import { useEffect, useMemo, useState } from 'react';
import { unstable_serialize as unstableSerialize } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { QueryParams } from 'api/APIClient';
import { ProductListingApi } from 'api/catalog';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import useCatalogFetcher from './useCatalogFetcher';
import { useDatajet } from './useDatajet';
import { useEnrichAttributes } from './useEnrichAttributes';

const useCatalogFilters = ({
  params = {},
  pageInfo,
  shouldFetch = true,
  isSearchCrawler,
}: {
  params: QueryParams;
  pageInfo: PageInfo;
  shouldFetch?: boolean;
  isSearchCrawler: boolean;
}) => {
  const fetcher = useCatalogFetcher(pageInfo);
  const {
    isLoading: isEnrichAttributeExpLoading,
    abTestParam: enrichABTestParam,
    isEnrichAttributeEnabled,
  } = useEnrichAttributes();
  const { abTestParam: djABTestParam } = useDatajet();
  const [hasFetched, setHasFetched] = useState(false);

  const swrKey = useMemo(() => {
    const shouldExecuteRequest = typeof shouldFetch === 'boolean' ? shouldFetch : true;

    if (!shouldExecuteRequest || isEnrichAttributeExpLoading) {
      return null;
    }

    // If it is search crawler request, data is already fetched on server side
    // so return key here to get data from cache as soon as possible
    if (isSearchCrawler || typeof fetcher === 'function') {
      return unstableSerialize([
        'products/filters',
        params,
        isEnrichAttributeEnabled ? enrichABTestParam : djABTestParam,
      ]);
    }

    return null;
  }, [
    fetcher,
    params,
    shouldFetch,
    isSearchCrawler,
    isEnrichAttributeExpLoading,
    enrichABTestParam,
    isEnrichAttributeEnabled,
    djABTestParam,
  ]);

  const {
    data: filters,
    isLoading,
    error,
  } = useSWRImmutable(
    swrKey,
    () => (typeof fetcher === 'function' ? fetcher(ProductListingApi.filter, params) : undefined),
    { keepPreviousData: !isSearchCrawler },
  );

  useEffect(() => {
    if (filters && !hasFetched) {
      setHasFetched(true);
    }
  }, [filters, hasFetched]);

  return {
    filters,
    isLoading,
    hasFetched,
    error,
  };
};

export default useCatalogFilters;
