import { LogoConfig } from 'types/footer';

/**
 * Ventures
 */

export const ZALORA_HK: LogoConfig = {
  name: 'hk',
  imageUrl: 'icon_hk_flag.svg',
  link: 'https://www.zalora.com.hk',
  title: 'Zalora Hong Kong',
};

export const ZALORA_ID: LogoConfig = {
  name: 'id',
  imageUrl: 'icon_id_flag.svg',
  link: 'https://www.zalora.co.id',
  title: 'Zalora Indonesia',
};

export const ZALORA_MO: LogoConfig = {
  ...ZALORA_HK,
  imageUrl: 'icon_mo_flag.svg',
  name: 'mo',
};

export const ZALORA_MY: LogoConfig = {
  name: 'my',
  imageUrl: 'icon_my_flag.svg',
  link: 'https://www.zalora.com.my',
  title: 'Zalora Malaysia',
};

export const ZALORA_PH: LogoConfig = {
  name: 'ph',
  imageUrl: 'icon_ph_flag.svg',
  link: 'https://www.zalora.com.ph',
  title: 'Zalora Philippines',
};

export const ZALORA_SG: LogoConfig = {
  name: 'sg',
  imageUrl: 'icon_sg_flag.svg',
  link: 'https://www.zalora.sg',
  title: 'Zalora Singapore',
};

export const ZALORA_TW: LogoConfig = {
  name: 'tw',
  imageUrl: 'icon_tw_flag.svg',
  link: 'https://www.zalora.com.tw',
  title: 'Zalora Taiwan',
};

/**
 * Other logos
 */
export const ALIPAY_HK: LogoConfig = {
  name: 'AlipayHK',
  imageUrl: 'icon_alipay_hk.png',
  width: 70,
  height: 28,
};

export const AMERICAN_EXPRESS: LogoConfig = {
  name: 'American Express',
  imageUrl: 'icon_american_express.svg',
};

export const ATOME: LogoConfig = {
  name: 'Atome',
  imageUrl: 'icon_atome.png',
  width: 70,
  height: 28,
};

export const BANK_CENTRAL_ASIA: LogoConfig = {
  name: 'Bank Central Asia',
  imageUrl: 'icon_bank_cena.svg',
  width: 60,
  height: 30,
};

export const BANK_MANDIRI: LogoConfig = {
  name: 'Bank Mandiri',
  imageUrl: 'icon_bank_bmri.svg',
  width: 70,
  height: 30,
};

export const BANK_NEGARA_ID: LogoConfig = {
  name: 'Bank Negara Indonesia',
  imageUrl: 'icon_bank_bni.svg',
  width: 50,
  height: 30,
};

export const BANK_RAKYAT_ID: LogoConfig = {
  name: 'Bank Rakyat Indonesia',
  imageUrl: 'icon_bank_brin.svg',
  width: 50,
  height: 30,
};

export const BLITZ: LogoConfig = {
  name: 'Blitz',
  imageUrl: 'icon_blitz.svg',
};

export const CASH_ON_DELIVERY: LogoConfig = {
  name: 'Cash on Delivery',
  imageUrl: 'icon_cod.svg',
};

export const CIMB: LogoConfig = {
  name: 'CIMB',
  imageUrl: 'icon_cimb.png',
  width: 100,
  height: 26,
};

export const CITIBANK: LogoConfig = {
  name: 'Citibank',
  imageUrl: 'icon_citi.png',
  className: 'mb-1.5',
};

export const CITY_LINK: LogoConfig = {
  name: 'City-Link Express',
  imageUrl: 'icon_city_link.svg',
};

export const CTBC: LogoConfig = {
  name: 'CTBC Bank',
  imageUrl: 'icon_ctbc.png',
  width: 100,
  height: 40,
};

export const DBS: LogoConfig = {
  name: 'DBS Bank',
  imageUrl: 'icon_dbs.png',
  width: 60,
  height: 26,
};

export const DBS_PAYLAH: LogoConfig = {
  name: 'DBS PayLah',
  imageUrl: 'icon_dbs-paylah.png',
};

export const ENTREGO: LogoConfig = {
  name: 'Entrego',
  imageUrl: 'icon_entrego.svg',
};

export const FUBON: LogoConfig = {
  name: 'Fubon Bank',
  imageUrl: 'icon_fubon.png',
  width: 100,
  height: 26,
};

export const GDEX: LogoConfig = {
  name: 'GDEX',
  imageUrl: 'icon_gdex.svg',
};

export const GOJEK: LogoConfig = {
  name: 'Gojek',
  imageUrl: 'icon_gojek.svg',
};

export const GOPAY: LogoConfig = {
  name: 'GOPAY',
  imageUrl: 'icon_gopay.png',
};

export const GOOGLE_PAY: LogoConfig = {
  name: 'Google Pay',
  imageUrl: 'icon_google_pay.svg',
};

export const ID_EXPRESS: LogoConfig = {
  name: 'ID Express',
  imageUrl: 'icon_idex.png',
};

export const INDOPAKET: LogoConfig = {
  name: 'INDOPAKET',
  imageUrl: 'icon_indopaket.svg',
};

export const JCB: LogoConfig = {
  name: 'JCB',
  imageUrl: 'icon_jcb.svg',
};

export const JKO_PAY: LogoConfig = {
  name: 'JKO Pay',
  imageUrl: 'icon_jkopay.png',
  width: 60,
  height: 30,
};

export const JNE: LogoConfig = {
  name: 'jne',
  imageUrl: 'icon_jne.svg',
};

export const JT_EXPRESS: LogoConfig = {
  name: 'J&T Express',
  imageUrl: 'icon_jt-express.svg',
};

export const KERRY_EXPRESS: LogoConfig = {
  name: 'Kerry Express',
  imageUrl: 'icon_kerry_express.svg',
};

export const KREDIVO: LogoConfig = {
  name: 'Kredivo',
  imageUrl: 'icon_kredivo.png',
};

export const LION_PARCEL: LogoConfig = {
  name: 'Lion Parcel',
  imageUrl: 'icon_lion_parcel.svg',
};

export const LINE_PAY: LogoConfig = {
  name: 'Line Pay',
  imageUrl: 'icon_line-pay.png',
  width: 60,
  height: 30,
};

export const MASTERCARD: LogoConfig = {
  name: 'Mastercard',
  imageUrl: 'icon_mastercard.png',
};

export const MULTISYS: LogoConfig = {
  name: 'Multisys',
  imageUrl: 'icon_multisys.svg',
};

export const NINJAVAN: LogoConfig = {
  name: 'Ninja Van',
  imageUrl: 'icon_ninjavan.svg',
};

export const OVO: LogoConfig = {
  name: 'OVO',
  imageUrl: 'icon_ovo.png',
};

export const PAYLATER_BY_GRAB: LogoConfig = {
  name: 'PayLater by Grab',
  imageUrl: 'icon_paylater-by-grab.svg',
  width: 110,
  height: 30,
};

export const PAYPAL: LogoConfig = {
  name: 'PayPal',
  imageUrl: 'icon_paypal.svg',
};

export const POS_INDONESIA: LogoConfig = {
  name: 'POS Indonesia',
  imageUrl: 'icon_pos_indonesia.svg',
};

export const SAP_EXPRESS: LogoConfig = {
  name: 'SAP Express',
  imageUrl: 'icon_sapexpress.svg',
};

export const SICEPAT_EKSPRES: LogoConfig = {
  name: 'SiCepat Ekspres',
  imageUrl: 'icon_sicepatekspres.svg',
};

export const SF_EXPRESS: LogoConfig = {
  name: 'SF Express',
  imageUrl: 'icon_sf-express.svg',
};

export const SINGPOST: LogoConfig = {
  name: 'SingPost',
  imageUrl: 'icon_singpost.svg',
};

export const TAISHIN: LogoConfig = {
  name: 'Taishin',
  imageUrl: 'icon_taishin.png',
  width: 100,
  height: 26,
};

export const TAQBIN: LogoConfig = {
  name: 'TaqBin',
  imageUrl: 'icon_taqbin.svg',
};

export const TELKOMSEL: LogoConfig = {
  name: 'Telkomsel',
  imageUrl: 'icon_telkomsel.svg',
};

export const UNION_PAY: LogoConfig = {
  name: 'UnionPay',
  imageUrl: 'icon_union_pay.png',
};

export const UPS: LogoConfig = {
  name: 'UPS',
  imageUrl: 'icon_ups.png',
  width: 31,
  height: 31,
};

export const VISA: LogoConfig = {
  name: 'Visa',
  imageUrl: 'icon_visa.png',
};

export const WECHAT_PAY_HK: LogoConfig = {
  name: 'WeChat Pay HK',
  imageUrl: 'icon_wechat-pay-hk.png',
  width: 90,
  height: 24,
};

export const WESTBIKE: LogoConfig = {
  name: 'WestBike',
  imageUrl: 'icon_westbike.svg',
};

export const ZALORA_DELIVERY_EXPRESS: LogoConfig = {
  name: 'Zalora Delivery Express',
  imageUrl: 'icon_zdex.svg',
};
