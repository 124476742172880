import { ZDTProduct } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';

export const EventNames = {
  CATEGORY_NAV_CLICKED: 'Category Nav Clicked',
  MID_CATALOG_FILTER_VIEWED: 'Mid-Catalog Filter Viewed',
  PRODUCTS_SEARCHED: 'Products Searched',
  PRODUCT_LIST_CATEGORY_REFINED: 'Product List Category Refined',
  PRODUCT_LIST_CLICKED: 'Product List Clicked',
  PRODUCT_LIST_FILTERED: 'Product List Filtered',
  PRODUCT_LIST_FILTER_CLEARED: 'Product List Filter Cleared',
  PRODUCT_LIST_SORTED: 'Product List Sorted',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_LIST_VIEWED_ITEM_LEVEL: 'Product List Viewed Item Level',
  SEARCH_BAR_CLICKED: 'Search Bar Clicked',
} as const;

export interface SegmentData {
  params: QueryParams;
  segment: string;
  categoryId: string;
  currentPage: number;
  catalogType?: string;
  listId?: string;
  numOfListedProducts?: number;
  vendor?: ZDTProduct.ProductVendor | null;
}
