import { SkeletonItem } from 'components/skeleton/SkeletonItem';

const MembershipPurchaseBannerSkeleton = ({ className = '' }: { className?: string }) => {
  return (
    <SkeletonItem
      height="h-14"
      className={className}
    />
  );
};

export default MembershipPurchaseBannerSkeleton;
