import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { CatalogFilterName, FILTER_REGULAR_GROUP, FilterName } from 'constants/catalog';
import { getInitialSelection } from 'utils/filters/categoryFilter';
import { getSelectedSizeSystemFromQuery, parseSizeFilter } from 'utils/filters/sizeFilter';
import { capitalizeFirstLetter } from 'utils/tracking';
import { PageInfo } from './catalog-page-type';
import { shouldShowRegularFilter } from './filters';
import { getPriceFilterLabel } from './price-filter';

export type RefinedFilter = {
  key: string;
  option: ZDTProduct.FilterOptions;
};

export const getAppliedFilters = (
  filters: ZDTCatalog.FilterResponse['Filters'] | undefined,
  categoryFilter: Nullishable<ZDTProduct.Filter>,
  params: QueryParams,
  pageInfo: PageInfo,
) => {
  if (!filters) {
    return [];
  }

  return filters.reduce((prev, filter) => {
    const { Id, Options } = filter;

    if (!Id) {
      return prev;
    }

    const isCategoryFilter =
      Id === CatalogFilterName.CATEGORY && categoryFilter && params.categoryId;

    if ((!Options || Options.length === 0) && !isCategoryFilter) {
      return prev;
    }

    if (filter.Group === FILTER_REGULAR_GROUP && !shouldShowRegularFilter(Id, pageInfo)) {
      return prev;
    }

    const selectedAttributes = getSelectedAttributes(
      isCategoryFilter ? categoryFilter : filter,
      params,
      pageInfo,
    );

    const refinedFilters = selectedAttributes.map((opt) => ({
      key: Id as FilterName,
      option: opt,
    }));

    return [...prev, ...refinedFilters];
  }, [] as RefinedFilter[]);
};

export const getSelectedAttributes = (
  filter: ZDTProduct.Filter,
  catalogParams?: QueryParams,
  pageInfo?: PageInfo,
) => {
  const { Options } = filter;
  const refinedFilters = Options?.filter((opt) => opt.Selected) || [];

  switch (filter.Id) {
    case CatalogFilterName.CATEGORY: {
      if (!catalogParams?.categoryId) {
        return [];
      }

      const { selectedOpts = [] } = getInitialSelection(filter, catalogParams.categoryId);

      // Dot not show applied filter for the category which is on the pathname
      return selectedOpts.filter((option) => {
        const { Value } = option;
        const { categoryId, subCategoryId } = pageInfo || {};

        return Value !== categoryId && Value !== subCategoryId;
      });
    }

    case CatalogFilterName.PRICE: {
      if (typeof catalogParams?.price !== 'string') {
        return refinedFilters;
      }

      const splittedRange = catalogParams.price.split('-');
      const minPrice = splittedRange[0];
      const maxPrice = splittedRange[1];

      const option: ZDTProduct.FilterOptions = {
        CategoryUrlKey: null,
        Label: getPriceFilterLabel(minPrice, maxPrice),
        ResultCount: 0,
        Selected: true,
        SubOptions: [],
        Value: catalogParams.price,
        SegmentUrls: null,
      };

      return [option];
    }

    case CatalogFilterName.SIZE: {
      return refinedSelectedSizeFilter(filter, catalogParams);
    }
  }

  return refinedFilters;
};

export const getSelectedString = (
  filter: ZDTProduct.Filter,
  catalogParams?: QueryParams,
  shouldCapitalize = false,
) => {
  const selectedOptionLabels = getSelectedAttributes(filter, catalogParams).map((opt) => opt.Label);

  if (!selectedOptionLabels?.length) {
    return '';
  }

  const selectedString = selectedOptionLabels.join(', ');

  return `(${shouldCapitalize ? capitalizeFirstLetter(selectedString) : selectedString})`;
};

/**
 * When user select filter size by US_38, we have to send to BE the same size of other size system.
 * For example, if user select US_38, we have to send EU_38, UK_10, International_M,...
 *
 * so that, BE returns multiple selected options for size filter. we have to group them to show in applied filter.
 */
export const refinedSelectedSizeFilter = (filter: ZDTProduct.Filter, params?: QueryParams) => {
  const selectedSizeSystem = params && getSelectedSizeSystemFromQuery(params);
  const { selectedSizeIndexes, sizeGroupBySizeSystem } = parseSizeFilter(
    filter,
    selectedSizeSystem,
  );

  if (!selectedSizeIndexes || !sizeGroupBySizeSystem) {
    return [];
  }

  const selectedSizeOptions = selectedSizeIndexes.reduce(
    (selectedOptionsMap, selectedIndex) => {
      const { value, label } = Object.values(sizeGroupBySizeSystem).reduce(
        (acc, sizes) => {
          // sizes[selectedIndex] could be undefined, ts wrongly types it
          // but we have to have a fallback to avoid runtime error
          const { Value, Label } = sizes[selectedIndex] || {};

          if (!Value || !Label) {
            return acc;
          }

          const isSelectedSizeSystem = selectedSizeSystem && Label.startsWith(selectedSizeSystem);

          // value will be list of same size in other size system
          // label will be selected size system
          return {
            value: isSelectedSizeSystem ? [Value, ...acc.value] : [...acc.value, Value],
            label: acc.label || (!selectedSizeSystem || isSelectedSizeSystem ? Label : ''),
          };
        },
        {
          value: [],
          label: '',
        } as { value: string[]; label: string },
      );

      const option: ZDTProduct.FilterOptions = {
        CategoryUrlKey: null,
        Label: label,
        ResultCount: null,
        Selected: null,
        SubOptions: [],
        Value: value.join('|'),
        SegmentUrls: null,
      };

      // There is a case that size Intl_S equal to multiple size of other size system
      // in this case, we have to merge them
      if (selectedOptionsMap[label]) {
        const existingOption = selectedOptionsMap[label];

        selectedOptionsMap[label] = {
          ...existingOption,
          Value: `${existingOption.Value}|${value.join('|')}`,
        };
      } else {
        selectedOptionsMap[label] = option;
      }

      return selectedOptionsMap;
    },
    {} as Record<string, ZDTProduct.FilterOptions>,
  );

  return Object.values(selectedSizeOptions);
};

export const removeAppliedFilter = (
  appliedFilters: RefinedFilter[],
  key: string,
  option: ZDTProduct.FilterOptions,
): ZDTProduct.FilterOptions[] => {
  const newSelectedFilter = appliedFilters.reduce((acc, filter) => {
    if (filter.key !== key || filter.option.Value === option.Value) {
      return acc;
    }

    switch (filter.key) {
      case CatalogFilterName.SIZE: {
        /**
         * Size filter Value has been refined by format: US_38|EU_38|UK_10 by function refinedSelectedSizeFilter
         * So that, we have to split it to get each size system.
         */
        const values = filter.option.Value;

        if (!values) {
          return acc;
        }

        const options: ZDTProduct.FilterOptions[] = values.split('|').map((Value) => {
          return {
            CategoryUrlKey: null,
            Label: null,
            ResultCount: null,
            Selected: null,
            SubOptions: [],
            Value,
            SegmentUrls: null,
          };
        });

        return [...acc, ...options];
      }
      default: {
        return [...acc, filter.option];
      }
    }
  }, [] as ZDTProduct.FilterOptions[]);

  return newSelectedFilter;
};
