import { ZDTProduct } from '@zalora/doraemon-ts';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

interface StoreItemProps {
  store: ZDTProduct.ProductStore;
  backgroundUrl: string;
}

export const StoreItem: FC<StoreItemProps> = ({ store, backgroundUrl }) => {
  const { SellerUrl = '' } = store;

  return (
    <Link
      href={`/store/${SellerUrl}`}
      className="mr-2 flex items-center rounded-md bg-cover bg-no-repeat p-2 pr-4"
      style={{ backgroundImage: `url("${backgroundUrl}")` }}
    >
      <div className="h-8 w-8">
        <Image
          alt={`logo of ${store.SellerName} store`}
          src={store.SellerLogoUrl || ''}
          height={32}
          width={32}
          className="overflow-hidden rounded-full object-cover"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
      <p className="ml-2 whitespace-nowrap text-sm font-medium">{store.SellerName}</p>
    </Link>
  );
};
