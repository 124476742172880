import { ZDTProduct } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { ExtendedZDTProductFilter, ExtendedZDTProductFilterOptions } from 'types/LDT';
import { PageInfo, isSearchPageInfo } from 'utils/catalog/catalog-page-type';

export type TActiveCategory = {
  Label?: string;
  SelectedOptionValues: string[];
  Value?: string;
};

export type TActiveCategories = TActiveCategory[];

export const getCategoryFilterParams = (params: QueryParams, pageInfo: PageInfo) => {
  const result = {
    ...params,
  };

  // on Search page, all info will be included in query param
  // otherwise, categories should be got from page info
  if (isSearchPageInfo(pageInfo)) {
    return result;
  }

  // remove categoryId & parentCategoryId if available on query params
  delete result.categoryId;
  delete result.parentCategoryId;

  // remove query params of Nextjs
  delete result.slugs;

  if (pageInfo.subCategoryId) {
    result.parentCategoryId = pageInfo.subCategoryId;
    result.categoryId = pageInfo.subCategoryId;
  } else if (pageInfo.categoryId) {
    result.parentCategoryId = pageInfo.categoryId;
    result.categoryId = pageInfo.categoryId;
  }

  return result;
};

export const normalizeCategoryFilter = (filter: ExtendedZDTProductFilter | ZDTProduct.Filter) => {
  const filterOptions = filter.Options || [];
  const normalisedCategoryFilter: ExtendedZDTProductFilter = {
    ...filter,
    Options: filterOptions.map((option) => {
      const modifiedOption: NonNullable<ExtendedZDTProductFilter['Options']>[number] = {
        ...option,
        get Category() {
          return {
            Label: option.Label || undefined,
            Value: option.Value || undefined,
          };
        },
        get SubOptions() {
          return addCategoryToSubOptions(option, this.Category);
        },
      };

      return modifiedOption;
    }),
  };

  return normalisedCategoryFilter;
};

export const normalizeSubOptions = (
  fallbackOption: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
  options?: ZDTProduct.FilterOptions[],
) => {
  if (!options) {
    return fallbackOption.SubOptions;
  }

  return options.map((suboption) => ({
    ...suboption,
    Category: 'Category' in fallbackOption ? fallbackOption.Category : undefined,
    SubOptions:
      'Category' in fallbackOption
        ? addCategoryToSubOptions(suboption, fallbackOption.Category)
        : null,
  }));
};

export const addCategoryToSubOptions = (
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions | ZDTProduct.FilterSuboption,
  category: { Label?: string; Value?: string } | undefined,
) => {
  if (!('SubOptions' in option) || !option.SubOptions) {
    return null;
  }

  if (!category) {
    return null;
  }

  return option.SubOptions.map((suboption) => {
    const updatedSubOption: ExtendedZDTProductFilterOptions = {
      ...suboption,
      Category: category,
      Selected: null,
      SubOptions: addCategoryToSubOptions(suboption, category),
    };

    return updatedSubOption;
  });
};

export const checkHasSubOptions = (
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
) => {
  if (!option.SubOptions) {
    return false;
  }

  return option.SubOptions.some((subOption) => {
    if ('SubOptions' in subOption) {
      return !!(subOption as ExtendedZDTProductFilterOptions).SubOptions;
    }

    return false;
  });
};

export const checkIsActiveCategory = (
  activeCategories: TActiveCategories,
  categoryValue: string,
) => {
  return !!activeCategories?.find((cat) => cat.Value === categoryValue);
};

export const getActiveCategoryNames = (activeCategories: TActiveCategories) => {
  if (!activeCategories.length) {
    return '';
  }

  const activeCategoryLabels = activeCategories.map((cat) => cat.Label);

  return `(${activeCategoryLabels.join(', ')})`;
};

export const getSubOptionValues = (
  option: ZDTProduct.FilterOptions | ZDTProduct.FilterSuboption,
): string[] => {
  if (!('SubOptions' in option) || !option.SubOptions) {
    return [];
  }

  let values: string[] = [];

  for (const suboption of option.SubOptions) {
    if (!suboption.Value) {
      continue;
    }

    values.push(suboption.Value);
    values = values.concat(getSubOptionValues(suboption));
  }

  return values;
};
