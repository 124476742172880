import { ZLoading } from '@zalora/zui';
import dynamic from 'next/dynamic';
import { FC } from 'react';

const FullScreenLoading = dynamic(
  () => import('@zalora/zui/dist/components/loading/full-screen-loading'),
  {
    ssr: false,
  },
);

export interface Props {
  isFullScreen?: boolean;
  fullBlock?: boolean;
}

const Loading: FC<Props> = ({ isFullScreen, fullBlock }) => {
  if (isFullScreen) {
    return <FullScreenLoading />;
  }

  return (
    <ZLoading
      size="medium"
      display={fullBlock ? 'block' : 'inline'}
    />
  );
};

export default Loading;
