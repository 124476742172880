import { ZDTSEO } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import { PageInfo, isSearchPageInfo } from 'utils/catalog/catalog-page-type';
import { getH1Content } from 'utils/catalog/catalog-seo';

interface Props {
  pageInfo: PageInfo;
  seoInfo: ZDTSEO.CatalogInfo;
  numOfProduct: number;
  query?: string;
}

const H1Content: FC<Props> = ({ pageInfo, seoInfo, numOfProduct, query }) => {
  const { t } = useTranslation();
  const h1Content = getH1Content({
    pageInfo,
    seoInfo,
    numOfProduct,
    t,
  });

  if (!h1Content) {
    return null;
  }

  return (
    <h1
      className={clsx(
        'text-xs text-grey-80',
        'tablet:text-lg tablet:text-grey-100',
        'desktop:text-2xl desktop:leading-9',
      )}
      data-test-id={TestIdsCatalog.CATALOG_INFO}
    >
      {h1Content}
      {isSearchPageInfo(pageInfo) ? (
        <span data-test-id={TestIdsCatalog.SEARCH_TERM_HEADER}> &quot;{query}&quot;</span>
      ) : null}
    </h1>
  );
};

export default H1Content;
