import { useTranslation } from 'next-i18next';
import * as React from 'react';

interface Props {
  shouldShowTooltip: boolean;
}

const TRIANGLE_WIDTH_IN_PIXELS = 28; // = diagonal distance of square
const TRIANGLE_SIDE_LENGTH = TRIANGLE_WIDTH_IN_PIXELS / Math.sqrt(2);

export const VariationsTooltip = ({ shouldShowTooltip }: Props) => {
  const { t } = useTranslation();

  if (!shouldShowTooltip) {
    return null;
  }

  return (
    <div className="relative flex w-20 items-center justify-end text-center text-teal-60">
      <span
        className="z-10 inline-flex h-7 items-center rounded-sm bg-teal-10 px-2 py-1 pr-2 text-xs"
        style={{ transform: `translateX(-${TRIANGLE_SIDE_LENGTH / 2}px)` }}
      >
        {t('Variations')}
      </span>
      <div
        className="absolute translate-x-[-50%] rotate-45 transform bg-teal-10"
        style={{
          height: TRIANGLE_SIDE_LENGTH,
          width: TRIANGLE_SIDE_LENGTH,
          right: -8,
        }}
      />
    </div>
  );
};
