import clsx from 'clsx';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { MAX_TIMES_SHOW_VARIATION_TOOLTIP } from 'constants/catalog';
import useCatalogScarcityProducts from 'hooks/api/scarcity/useCatalogScarcityProducts';
import { usePromotion } from 'hooks/api/usePromotion';
import { trackingOnViewedProductInCatalog } from 'pages/catalog/tracking/trackingOnViewedProductInCatalog';
import { SegmentData } from 'pages/catalog/types';
import { useCatalogDispatch } from 'stores/catalog';
import { getSourceCatalogListId } from 'utils/catalog/tracking';
import { throttleUpdateImpressedProduct } from 'utils/catalog/update-impressed-products';
import isServer from 'utils/is-server';
import { getCountVariationsOpenedTimes } from 'utils/local-storage';
import CatalogProduct from '../CatalogProduct';
import QuickSizeFilter from '../QuickSizeFilter';

interface Props {
  segmentData: SegmentData;
}

const CatalogProducts: FC<Props> = ({ segmentData }) => {
  const {
    data: { products, sponsoredProducts },
    configSkus,
  } = useDynamicCatalogContext();
  const { seoInfo, impressedProducts, sourceCatalog } = useStaticCatalogContext();
  const catalogDispatch = useCatalogDispatch();
  //TODO: create a useCatalogPromotion, which receives no input and get configSkus from catalog store after we move `products` to store
  const { data: promotions, isLoading: isPromotionLoading } = usePromotion(configSkus);

  const { data: scarcities, isLoading: isScarcitiesLoading } = useCatalogScarcityProducts();

  const numOfSponsoredProducts = sponsoredProducts?.Products?.length || 0;
  const numOfListedProducts = (products?.Products?.length || 0) + numOfSponsoredProducts;

  /**
   * catalogType, listId is using for tracking
   * it is not necessary to get on server side
   */
  const [catalogType, listId] = useMemo(
    () => (isServer() ? [] : getSourceCatalogListId(seoInfo)),
    [seoInfo],
  );

  const updatedSegmentData = useMemo(
    () => ({
      ...segmentData,
      catalogType,
      listId,
      numOfListedProducts,
    }),
    [catalogType, listId, numOfListedProducts, segmentData],
  );

  const updateImpressedProducts = useCallback(
    (sku: string) => {
      if (!impressedProducts || !impressedProducts.current) {
        return;
      }

      throttleUpdateImpressedProduct(impressedProducts.current, sku);
    },
    [impressedProducts],
  );

  useEffect(() => {
    catalogDispatch.showVariantTooltip(
      getCountVariationsOpenedTimes() < MAX_TIMES_SHOW_VARIATION_TOOLTIP,
    );
  }, [catalogDispatch]);

  useEffect(() => {
    const getFirstProductHasVariations = () => {
      if (sponsoredProducts?.Products?.length) {
        const sponsoredProductHasVariations = sponsoredProducts.Products.find(
          ({ Variations }) => Variations && Variations.length > 1,
        );

        if (sponsoredProductHasVariations?.ConfigSku) {
          catalogDispatch.setSkuFirstProductHasVariations(sponsoredProductHasVariations.ConfigSku);
        }

        return;
      }

      const productHasVariations = products?.Products?.find(
        (p) => p?.Variations && p.Variations.length > 1,
      );

      if (productHasVariations?.ConfigSku) {
        catalogDispatch.setSkuFirstProductHasVariations(productHasVariations.ConfigSku);
      }
    };

    if (getCountVariationsOpenedTimes() < MAX_TIMES_SHOW_VARIATION_TOOLTIP) {
      getFirstProductHasVariations();
    }
  }, [sponsoredProducts, products, catalogDispatch]);

  if (!products || !products.Products) {
    return null;
  }

  return (
    <div
      className={clsx(
        'grid grid-cols-2 gap-2',
        'tablet:grid-cols-4 tablet:gap-8',
        'desktop:grid-cols-3 desktop:gap-6',
      )}
    >
      {/* eslint-disable-next-line @sayari/no-unwrapped-jsx-text */}
      {sponsoredProducts && sponsoredProducts.Products
        ? sponsoredProducts.Products.map((product, idx) => (
            <CatalogProduct
              index={idx + 1}
              trackingData={product.ConfigSku}
              key={product.ConfigSku}
              product={product}
              order={(idx + 1) * 10}
              segmentData={updatedSegmentData}
              onImpression={() => {
                trackingOnViewedProductInCatalog({
                  index: idx + 1,
                  sourceCatalog: sourceCatalog || catalogType,
                  listId,
                  product,
                  promotions,
                  segmentData: updatedSegmentData,
                  scarcityProducts: scarcities,
                  isImpressed: impressedProducts?.current?.includes(product.ConfigSku as string),
                });

                if (product.ConfigSku && typeof updateImpressedProducts === 'function') {
                  updateImpressedProducts(product.ConfigSku);
                }
              }}
              conditionInvokeCallback={!(isPromotionLoading || isScarcitiesLoading)}
            />
          ))
        : null}

      {products.Products.map((product, idx) => (
        <CatalogProduct
          index={numOfSponsoredProducts + idx + 1}
          trackingData={product.ConfigSku}
          key={product.ConfigSku}
          product={product}
          order={(numOfSponsoredProducts + idx + 1) * 10}
          segmentData={updatedSegmentData}
          onImpression={() => {
            trackingOnViewedProductInCatalog({
              index: numOfSponsoredProducts + idx + 1,
              product,
              segmentData: updatedSegmentData,
              sourceCatalog: sourceCatalog || catalogType,
              listId,
              promotions,
              scarcityProducts: scarcities,
              isImpressed: impressedProducts?.current?.includes(product.ConfigSku as string),
            });

            product.ConfigSku && updateImpressedProducts(product.ConfigSku);
          }}
          conditionInvokeCallback={!(isPromotionLoading || isScarcitiesLoading)}
        />
      ))}

      <QuickSizeFilter
        className={clsx(
          'order-[60] col-span-2 rounded-lg',
          'tablet:order-[120] tablet:col-span-4',
          'desktop:order-[90] desktop:col-span-3',
        )}
      />
    </div>
  );
};

export default memo(CatalogProducts);
