import clsx from 'clsx';
import { ComponentPropsWithoutRef } from 'react';

export const Card = ({ className, children, ...props }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={clsx('rounded-2xl p-4 shadow-lg', className)}
      {...props}
    >
      {children}
    </div>
  );
};
