import { CatalogStore, CatalogStoreActions, CatalogStoreObservables } from './types';

export const skuFirstProductHasVariations = (state: CatalogStoreObservables) => {
  return state.skuFirstProductHasVariations;
};

export const shouldShowVariantTooltip = (state: CatalogStoreObservables) => {
  return state.shouldShowVariantTooltip;
};

export const actions = (state: CatalogStore): Pick<CatalogStore, keyof CatalogStoreActions> => {
  return {
    showVariantTooltip: state.showVariantTooltip,
    setSkuFirstProductHasVariations: state.setSkuFirstProductHasVariations,
  };
};
