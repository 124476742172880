import { useDisclosure } from '@zalora/zui';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { hasErrorBannerCookie, removeErrorBannerCookie } from 'utils/errorsHandler';

const ErrorBannerContent = dynamic(() => import('./ErrorBannerContent'));

export const ErrorBanner: FC = () => {
  const { isOpen, open, close } = useDisclosure(false);
  const router = useRouter();

  useEffect(() => {
    const hasCookie = hasErrorBannerCookie();

    hasCookie ? open() : close();

    removeErrorBannerCookie();
  }, [router.asPath, open, close]);

  if (!isOpen) {
    return null;
  }

  return <ErrorBannerContent />;
};
