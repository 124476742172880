import { ZaloraVipIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import { PRODUCT_LIST_FILTER_LOCATION } from 'constants/tracking';
import { IFilterItem } from '../Filter/FilterContent/FilterItem';
import { FilterButton } from './FilterButton';

const ZaloraVipFilterButton: FC<IFilterItem> = ({ filter, onChange, className = '' }) => {
  const filterId = filter.Id || undefined;

  const zaloraVipOption = useMemo(() => {
    const filterOptions = filter.Options || [];

    if (filterOptions.length === 0) {
      return undefined;
    }

    return filterOptions.find((opt) => opt.Value === 'only');
  }, [filter]);

  const isZaloraVipFilterActive = !!zaloraVipOption?.Selected;

  const toggleZaloraVipFilter = () => {
    if (!zaloraVipOption || !onChange) {
      return;
    }

    onChange(
      filterId,
      !isZaloraVipFilterActive ? zaloraVipOption : [],
      PRODUCT_LIST_FILTER_LOCATION.ONE_CLICK,
    );
  };

  return (
    <FilterButton
      className="mr-4"
      isActive={isZaloraVipFilterActive}
      onClick={toggleZaloraVipFilter}
      data-test-id={TestIdsCatalog.ZALORA_VIP_FILTER_BUTTON}
      aria-label="VIP Filter Button"
    >
      <ZaloraVipIcon className={clsx('mb-[1px] h-4 w-[46px]', className)} />
    </FilterButton>
  );
};

export default ZaloraVipFilterButton;
