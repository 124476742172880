import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { NewArrivalIcon, PrelovedIcon, ShootingStarIcon, TrendingIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { TFuncKey } from 'i18next';
import { useTranslation } from 'next-i18next';
import { FC, memo } from 'react';
import { FALSE } from 'constants/optimizely';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';

interface Props {
  product: ZDTCatalog.Product | ZDTProduct.Product;
}

interface Tag {
  textClassName: string;
  bgClassName: string;
  renderIcon: () => JSX.Element;
  text: TFuncKey<'common'>;
  key: string;
}

const TAG_MAP: Record<string, Tag> = {
  preloved: {
    textClassName: 'text-green-60',
    bgClassName: 'bg-[#E8FCE2]',
    renderIcon: () => <PrelovedIcon className="!size-2" />,
    text: 'Pre-loved',
    key: 'preloved',
  },
  new_arrival: {
    textClassName: 'text-yellow-90',
    bgClassName: 'bg-yellow-10',
    renderIcon: () => <NewArrivalIcon className="!size-2" />,
    text: 'New Arrivals',
    key: 'new_arrival',
  },
  trending: {
    textClassName: 'text-[#34669F]',
    bgClassName: 'bg-[#E5F2FF]',
    renderIcon: () => <TrendingIcon className="!size-2" />,
    text: 'Trending',
    key: 'trending',
  },
  best_price: {
    textClassName: 'text-best-price-600',
    bgClassName: 'bg-best-price-100',
    renderIcon: () => <ShootingStarIcon className="!size-2" />,
    text: 'Best Price',
    key: 'best_price',
  },
} as const;

const CatalogProductMetaLabel: FC<Props> = ({ product }) => {
  const { t } = useTranslation('common');
  const bestPriceGuaranteeValue = useOptimizelyStore(optimizelySelector.bestPriceGuaranteeValue);

  const { Overlays, SpecialLabel, IsBestPriceGuarantee } = product;

  if (IsBestPriceGuarantee && bestPriceGuaranteeValue === undefined) {
    // avoid fickering when the flag is still loading
    return null;
  }

  let tag: Tag | undefined = undefined;

  if (bestPriceGuaranteeValue !== FALSE && IsBestPriceGuarantee) {
    tag = TAG_MAP.best_price;
  } else if (Overlays && Overlays.some(({ TitleText }) => TitleText === 'PRE-LOVED')) {
    tag = TAG_MAP.preloved;
  } else if (SpecialLabel === 'new_arrival') {
    tag = TAG_MAP.new_arrival;
  } else if (SpecialLabel === 'trending') {
    tag = TAG_MAP.trending;
  }

  if (!tag) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex items-center gap-x-[1px] whitespace-nowrap rounded p-0.5 text-xxxs font-semibold desktop:text-xxs',
        tag.bgClassName,
      )}
      data-test-id={tag.key}
    >
      {tag.renderIcon()}
      <span className={tag.textClassName}>{t(tag.text)}</span>
    </div>
  );
};

export default memo(CatalogProductMetaLabel);
