import { TrackingDataProps } from 'constants/tracking';
import isServer from 'utils/is-server';

export const initialiseTrackingData = (trackingData: TrackingDataProps) => {
  if (isServer()) {
    return;
  }

  window.trackingData = trackingData;
};

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return '';
};

export const getNextWeek = () => {
  const expireTime = new Date();

  expireTime.setDate(expireTime.getDate() + 7);

  return expireTime.toUTCString();
};
