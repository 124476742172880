import { ZDTAds } from '@zalora/doraemon-ts';
import { CatalogFilterName } from 'constants/catalog';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import { getCatalogParams } from 'utils/catalog/query';
import { omit } from 'utils/omit';
import { HEADERS, QueryParams, simpleFetch } from './APIClient';

export const getCatalogAdsBanner = async (params: QueryParams) => {
  const res = await simpleFetch<{ Banners: ZDTAds.Banner[] }>(`/v1/ads/banners`, {
    params,
    credentials: 'include',
  });

  return res.data;
};

export const getCMSBanner = async (pageInfo: PageInfo, query: QueryParams, locale: string) => {
  const cleanQuery = omit(query, [CatalogFilterName.BRAND, CatalogFilterName.CATEGORY]);
  const catalogParams = getCatalogParams(cleanQuery, pageInfo);
  const res = await simpleFetch<ZDTAds.Banner>(`/v1/catalog/banner`, {
    params: catalogParams,
    headers: {
      [HEADERS.CONTENT_LANG]: locale,
    },
    credentials: 'include',
  });

  return res.data;
};
