import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { useDynamicCatalogContext } from 'context/CatalogContext';
import { QueryParams } from 'api/APIClient';
import { PRODUCT_LIST_FILTER_LOCATION } from 'constants/tracking';
import { getCurrentSegment } from 'pages/common.tracking';
import { SocialProofingMetricItem } from 'types/SocialProofing';
import { ZDTPromotion } from 'types/ZDT';
import {
  ProductListCategoryRefinedTrackingPayload,
  ProductListClickedTrackingPayload,
  ProductListFilterClearedTrackingPayload,
  ProductListFilteredTrackingPayload,
  ProductListSortedTrackingPayload,
} from 'types/trackingPayload/ProductListTrackingPayload';
import {
  getCategory,
  getSourceSegment,
  getSubcategory,
  getSubcategoryBreadcrumbs,
} from 'utils/catalog-product';
import { RefinedFilter } from 'utils/catalog/applied-filter';
import { getSourceCatalogListId } from 'utils/catalog/tracking';
import isServer from 'utils/is-server';
import { isSearchPage } from 'utils/page-type';
import * as productUtils from 'utils/product';
import * as SegmentTracking from 'utils/segment-analytic';
import { getSocialProofingTrackingProperties } from 'utils/socialProofing';
import { prepareProductTrackingDetails } from 'utils/tracking/common';
import { getSearchMethod, isOriginalSearchPage } from 'utils/tracking/search';
import { trackingClick } from 'utils/tracking/zap';
import { getAbsoluteProductUrlFromSlug } from 'utils/url';
import { EventNames, SegmentData } from './types';

export const trackingNoResultsReturned = (
  queryParams: QueryParams,
  suggestedQuery?: ZDTProduct.ZeroSearchResultSuggestion[],
) => {
  const query = queryParams.q;

  if (!query || !isSearchPage(window.location.pathname) || !isOriginalSearchPage()) {
    return;
  }

  const payload = {
    query,
    search_method: getSearchMethod(queryParams),
    suggested_query: suggestedQuery?.map(({ Text }) => Text),
  };

  SegmentTracking.track('Zero Search Result Returned', payload);
};

/* eslint-disable max-lines */

const SUPPLIER_NAME_EN = 'ZALORA';

const ITEMS_PER_ROW = !isServer() ? SegmentTracking.getNumCatalogItemPerRow() : 0;

export const getPosition = (index: number) => {
  return {
    y_position: typeof index === 'number' ? String(Math.ceil(index / ITEMS_PER_ROW)) : '',
    x_position: typeof index === 'number' ? String(index % ITEMS_PER_ROW || ITEMS_PER_ROW) : '',
  };
};

const triggerTrackingProductOnRecom = (Tracking: ZDTProduct.Tracking) => {
  Tracking.ClickTrackingUrl && fetch(Tracking.ClickTrackingUrl);
};

export const formatActiveFilters = (activeFilters: ZDTProduct.Filter[] | undefined) => {
  if (!activeFilters) {
    return [];
  }

  return activeFilters.map((activeFilter) => ({
    type: activeFilter.Id || '',
    value:
      activeFilter.Options?.reduce((val, option) => {
        return option.Selected ? `${val}${option.Label},` : val;
      }, '').replace(/,$/, '') || '',
  }));
};

export const formatAppliedFilters = (appliedFilters: RefinedFilter[]) => {
  if (!appliedFilters.length) {
    return [];
  }

  return appliedFilters.reduce(
    (filters, currentFilter) => {
      const currentKey = currentFilter.key;
      const matchingFilter = filters.find((filter) => filter.type === currentKey);

      if (!matchingFilter) {
        return filters.concat({
          type: currentKey,
          value: currentFilter.option.Label || '',
        });
      }

      matchingFilter.value += `,${currentFilter.option.Label}`;

      return filters;
    },
    [] as { type: string; value: string }[],
  );
};

export const trackingOnClickProductInCatalog = ({
  product,
  index,
  segmentData,
  appliedFilters,
  sourceCatalog,
  listId,
  promotions,
  socialProofingData,
}: {
  product: ZDTCatalog.Product | ZDTProduct.Product;
  index: number;
  segmentData: SegmentData;
  appliedFilters: RefinedFilter[];
  sourceCatalog?: string;
  listId?: string;
  promotions?: ZDTPromotion.PromotionMap['Promotions'];
  socialProofingData?: SocialProofingMetricItem[];
}) => {
  const { AdId, Tracking } = product;

  // Trigger sponsored event
  if (AdId) {
    trackingClick(AdId);
  }

  if (Tracking) {
    triggerTrackingProductOnRecom(Tracking);
  }

  // Trigger Segment event
  const segmentFromBreadcrumbs = product ? getSourceSegment(product) : '';
  const [defaultSourceCatalog, defaultListId] = getSourceCatalogListId(
    window.trackingData?.seoInfo,
  );

  const payload: ProductListClickedTrackingPayload = {
    source_segment: segmentFromBreadcrumbs || '',
    source_catalog: sourceCatalog || defaultSourceCatalog,
    list_id: listId || defaultListId,
    gridview_selected: true, // We only have gridview on Lotus
    category: product ? getCategory(product) : '',
    no_of_filters_applied: appliedFilters.length,
    array_of_filters_applied: formatAppliedFilters(appliedFilters),
    pagination: String(segmentData.currentPage || 1),
    pagination_upperbound: segmentData.numOfListedProducts || 0,
    products: [
      {
        ...prepareProductTrackingDetails(product, promotions),
        ...getSocialProofingTrackingProperties(socialProofingData || []),
      },
    ],
    ...getPosition(index),
    vendor_version_id: segmentData.vendor?.VersionId || undefined,
    vendor_source: segmentData.vendor?.Source || undefined,
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_CLICKED, payload);
};

export const trackingOnProductListFiltered = ({
  catalogData,
  appliedFilters,
  filterLocation,
  sourceCatalog,
  listId,
  params,
}: {
  catalogData: ReturnType<typeof useDynamicCatalogContext>['data'];
  appliedFilters: {
    type: string;
    value: string;
  }[];
  filterLocation?: string;
  sourceCatalog?: string;
  listId?: string;
  params: QueryParams;
}) => {
  const { filters } = catalogData;

  if (!filters) {
    return;
  }

  const { segment } = params;
  const [defaultSourceCatalog, defaultListId] = getSourceCatalogListId(
    window.trackingData?.seoInfo,
  );

  const payload: ProductListFilteredTrackingPayload = {
    filters: appliedFilters,
    list_id: listId || defaultListId,
    source_catalog: sourceCatalog || defaultSourceCatalog,
    source_segment: segment as string,
    ...(filterLocation
      ? { filterLocation }
      : { filterLocation: PRODUCT_LIST_FILTER_LOCATION.NORMAL }),
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_FILTERED, payload);
};

export const trackingOnProductListFilterCleared = (
  activeFilters: RefinedFilter[],
  clearFilterType: 'Individual' | 'Clear All',
) => {
  const payload: ProductListFilterClearedTrackingPayload = {
    clear_filter_type: clearFilterType,
    filters: formatAppliedFilters(activeFilters),
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_FILTER_CLEARED, payload);
};

export const trackingOnProductListSorted = ({
  sortType,
  sourceCatalog,
  listId,
  params,
}: {
  catalogData: ReturnType<typeof useDynamicCatalogContext>['data'];
  sortType: string;
  sourceCatalog?: string;
  listId?: string;
  params: QueryParams;
}) => {
  const { segment } = params;
  const [defaultSourceCatalog, defaultListId] = getSourceCatalogListId(
    window.trackingData?.seoInfo,
  );

  const payload: ProductListSortedTrackingPayload = {
    list_id: listId || defaultListId,
    source_catalog: sourceCatalog || defaultSourceCatalog,
    source_segment: segment as string,
    sort_type: sortType,
    sort_location: 'menu',
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_SORTED, payload);
};

export const trackingOnMidCatalogFilterViewed = (
  catalogData: ReturnType<typeof useDynamicCatalogContext>['data'],
  params: QueryParams,
) => {
  const products = catalogData.products;
  const { currentPage, limit, segment } = params;

  const [sourceCatalog, listId] = getSourceCatalogListId(window.trackingData?.seoInfo);
  const productsPerPage = limit as number;
  const totalPages = products?.NumProductFound
    ? Math.ceil(products.NumProductFound / productsPerPage)
    : 0;

  const payload = {
    list_id: listId,
    source_catalog: sourceCatalog,
    source_segment: segment,
    gridview_selected: true, // We only have gridview on Lotus
    pagination: currentPage,
    pagination_upperbound: totalPages,
    products:
      products?.Products?.map((product) => ({
        sku: product.ConfigSku,
        countrySKU: product.ConfigSku
          ? productUtils.getCountrySkuByConfigSku(product.ConfigSku)
          : '',
        category: getCategory(product),
        subcategory_breadcrumb: getSubcategoryBreadcrumbs(product),
        subcategory: getSubcategory(product),
        name: product.Name,
        brand: product.Brand,
        gender: product.Brand,
        price_original: product.Price,
        price: product.SpecialPrice,
        on_sale: !!product.SpecialPrice,
        colour: '',
        marketplace: product.SupplierNameEn !== SUPPLIER_NAME_EN,
        url: product.ProductUrl ? getAbsoluteProductUrlFromSlug(product.ProductUrl) : '',
        image_url: product.MainImageUrl,
      })) || [],
  };

  SegmentTracking.track(EventNames.MID_CATALOG_FILTER_VIEWED, payload);
};

export const trackingOnClickRefineFilter = ({
  cateName,
  sourceCatalog,
  listId,
}: {
  cateName: string;
  sourceCatalog?: string;
  listId?: string;
}) => {
  const [defaultSourceCatalog, defaultListId] = getSourceCatalogListId(
    window.trackingData?.seoInfo,
  );

  const payload: ProductListCategoryRefinedTrackingPayload = {
    source_catalog: sourceCatalog || defaultSourceCatalog,
    list_id: listId || defaultListId,
    refined_category_type: cateName,
    source_segment: getCurrentSegment(),
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_CATEGORY_REFINED, payload);
};
