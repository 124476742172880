import { Button } from '@zalora/zui';
import { EmptyStateIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';

export const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center gap-4 text-center',
        'min-h-screen max-w-[512px]',
        'mx-auto px-4 pb-[70px]',
      )}
      data-test-id={TestIdsCatalog.NO_RESULTS}
    >
      <EmptyStateIcon className="h-[224px] w-[224px]" />
      <h1 className="text-base font-medium text-grey-100">{t('No results found')}</h1>
      <p className="text-sm font-medium text-grey-60">
        {t('Sorry, we couldn’t find any items that matched your criteria')}
      </p>
      <Button
        aria-label="Search"
        className="w-full rounded-lg text-base"
      >
        {t('Search')}
      </Button>
    </div>
  );
};
