import clsx from 'clsx';
import { ComponentPropsWithRef, ReactNode } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';

interface Props extends ComponentPropsWithRef<'button'> {
  isActive?: boolean;
  icon?: ReactNode;
}

export const FilterButton = ({
  children,
  className,
  isActive = false,
  icon = '',
  ...props
}: Props) => {
  return (
    <button
      data-test-id={TestIdsCatalog.FILTER_BUTTON}
      className={clsx(
        'inline-flex min-h-8 items-center whitespace-nowrap rounded-md border px-2 text-xs text-grey-80',
        isActive ? 'border-grey-100 bg-grey-10' : 'border-grey-20',
        className,
      )}
      {...props}
    >
      <span>{children}</span>
      {icon}
    </button>
  );
};
