import {
  ColoredHandbagIcon,
  ColoredHeartIcon,
  ColoredCheckCircleIcon,
  ColoredEyeIcon,
} from '@zalora/zui-icons';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';
import { SocialProofingMetric } from 'types/SocialProofing';
import SocialProofingItem from './SocialProofingItem';

interface Props {
  label: string;
  count: number;
  duration?: number;
  isCatalogPage: boolean;
}

const SocialProofingWrapper: FC<Props> = ({ label, count, duration, isCatalogPage }) => {
  const { t } = useTranslation(isCatalogPage ? 'catalog' : 'pdv');

  const socialProofingMarkup = useMemo(() => {
    const itemClassName = clsx({ 'pr-1': !isCatalogPage, '-ml-1': isCatalogPage });
    const innerClassName = clsx({ 'mt-1 text-xs': isCatalogPage, 'text-xs/6': !isCatalogPage });
    const transComponents = isCatalogPage
      ? { bold: <span className="font-bold text-black" /> }
      : undefined;

    switch (label) {
      case SocialProofingMetric.CountInBag: {
        return (
          <SocialProofingItem
            className={itemClassName}
            innerClassName={innerClassName}
            icon={<ColoredHandbagIcon className="py-1" />}
            message={
              <Trans
                t={t}
                i18nKey="Added into bag by {{ count }} people"
                values={{ count }}
                components={transComponents}
              />
            }
          />
        );
      }

      case SocialProofingMetric.CountInWishlist: {
        return (
          <SocialProofingItem
            className={itemClassName}
            innerClassName={innerClassName}
            icon={<ColoredHeartIcon className="mr-[1.5px] py-1" />}
            message={
              <Trans
                t={t}
                i18nKey="Liked by {{ count }} people"
                values={{ count }}
                components={transComponents}
              />
            }
          />
        );
      }

      case SocialProofingMetric.CountInSales: {
        return (
          <SocialProofingItem
            className={itemClassName}
            innerClassName={innerClassName}
            icon={<ColoredCheckCircleIcon className="py-1" />}
            message={
              <Trans
                t={t}
                i18nKey="{{ count }} purchased in last {{ duration }} days"
                values={{ count, duration }}
                components={transComponents}
              />
            }
          />
        );
      }

      case SocialProofingMetric.CountInViews: {
        return (
          <SocialProofingItem
            className={itemClassName}
            innerClassName={innerClassName}
            icon={<ColoredEyeIcon className="py-1" />}
            message={
              <Trans
                t={t}
                i18nKey="{{ count }} people viewed in {{ duration }} days"
                values={{ count, duration }}
                components={transComponents}
              />
            }
          />
        );
      }
    }
  }, [count, isCatalogPage, label, t, duration]);

  return <>{socialProofingMarkup}</>;
};

export default SocialProofingWrapper;
