import { FC } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';

const CatalogProductAdLabel: FC = () => {
  return (
    <div className="flex items-center">
      <span
        className="text-xxxs font-bold text-grey-60 desktop:text-xs desktop:leading-normal"
        data-test-id={TestIdsCatalog.AD}
      >
        AD{/* same string for all ventures  */}
      </span>
    </div>
  );
};

export default CatalogProductAdLabel;
