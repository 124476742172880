import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';

export const UspBarSkeleton: FC = () => {
  return (
    <div className="hidden bg-grey-10 py-2 desktop:block">
      <div className="mx-auto flex h-6 w-screen-xl max-w-full justify-between px-4">
        {Array.from({ length: 3 }).map((_, idx) => (
          <div
            key={idx}
            className="flex items-center gap-2"
          >
            <SkeletonItem
              width="w-5"
              height="h-5"
            />
            <SkeletonItem width="w-36" />
          </div>
        ))}
      </div>
    </div>
  );
};
