import { ZDTAds } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { getCatalogAdsBanner } from 'api/banner';
import { CatalogFilterName, SPONSORED_PRODUCT_PER_PAGE } from 'constants/catalog';
import { omit } from 'utils/omit';
import { PageInfo } from './catalog-page-type';
import { getCatalogParams } from './query';

interface AdsProps {
  isFullScreenBanner: boolean;
}

/**
 * CATEGORY_SEARCH: banner slot in desktop website’s category or search page
 * CATEGORY_SEARCH_MOBILE: banner slot in the mobile website’s category or search page
 * CATEGORY_SEARCH_APP: banner slot in the mobile application’s category or search page
 * TILE_MOBILE: tile banner slot in the mobile application’s category or search page
 */
export const enum CatalogBannerType {
  CategorySearch = 'CATEGORY_SEARCH',
  CategorySearchApp = 'CATEGORY_SEARCH_APP',
  CategorySearchMobile = 'CATEGORY_SEARCH_MOBILE',
  TileMobile = 'TILE_MOBILE',
}

const FULL_WIDTH_BANNER_PREFIX = '<!-- FULL_SCREEN_BANNER -->';

const hasComment = (cmsData: string, comment: string): boolean => {
  return cmsData.startsWith(comment) || cmsData.includes(comment);
};

/**
 * This function will check content of BannerFullWidth, if it starts with '<!-- FULL_SCREEN_BANNER -->' comment,
 * then it will set isFullScreenBanner to true
 *
 * @param CatalogBanner
 * @returns
 */
export const transformCMSBanner = (
  CatalogBanner: Nullishable<ZDTAds.Banner>,
): (ZDTAds.Banner & AdsProps) | null => {
  if (!CatalogBanner) {
    return null;
  }

  const catalogBanner: ZDTAds.Banner & AdsProps = Object.assign(
    { isFullScreenBanner: false },
    CatalogBanner,
  );

  if (
    catalogBanner.BannerFullWidth &&
    hasComment(catalogBanner.BannerFullWidth, FULL_WIDTH_BANNER_PREFIX)
  ) {
    catalogBanner.isFullScreenBanner = true;
  }

  return catalogBanner;
};

export const getHrefLang = (hrefLangTag: Nullishable<string>) => {
  if (!hrefLangTag) {
    return;
  }

  try {
    const hrefLangString = hrefLangTag.split(',');

    const hrefLang = hrefLangString.reduce(
      (hrefLang, currString) => {
        const [locale, link] = currString.split('=');

        hrefLang[locale] = link;

        return hrefLang;
      },
      {} as Record<string, string>,
    );

    return hrefLang;
  } catch (err) {}
};

export const getCatalogAds = async (pageInfo: PageInfo, query: QueryParams) => {
  // Ads info will use the information from pathname,
  // so we need to remove brand and category from query
  const cleanQuery = omit(query, [CatalogFilterName.BRAND, CatalogFilterName.CATEGORY]);
  const catalogParams = getCatalogParams(cleanQuery, pageInfo);
  const page = Number(query.page || 1);
  const data = await getCatalogAdsBanner({
    ...catalogParams,
    offset: (page - 1) * SPONSORED_PRODUCT_PER_PAGE,
    limit: SPONSORED_PRODUCT_PER_PAGE,
  });

  if (!data) {
    return null;
  }

  /**
   * API will return many banners (maybe for carousel purpose)
   * But we don't use all of them, so we will filter and get only 2 banners
   * 1 for mobile, 1 for desktop
   */

  const mobileBanner = data.Banners?.find(
    (banner) => banner.TypeA1 === CatalogBannerType.CategorySearchMobile,
  );

  const desktopBanner = data.Banners?.find(
    (banner) => banner.TypeA1 === CatalogBannerType.CategorySearch,
  );
  const { Banners, ...rest } = data;

  return {
    ...rest,
    CatalogBanners: [mobileBanner || null, desktopBanner || null] as ZDTAds.Banner[],
  };
};
