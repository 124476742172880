/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import { FC, useRef } from 'react';

interface Props {
  src: string;
  hoverSrc: string;
  alt: string;
  index?: number;
}

/**
 * CatalogProductImage is the lightweight version of `Image` of `next/image` component.
 * `Image` component have many out of the box features but we don't need them here.
 * Using this component will reduce the usage of `next/image` and improve the performance.
 */
const CatalogProductImage: FC<Props> = ({ src, hoverSrc, alt, index = -1 }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <>
      {index === 1 ? (
        <Head>
          <link
            rel="preload"
            as="image"
            href={src}
          />
        </Head>
      ) : null}

      <img
        ref={imageRef}
        onMouseOver={() => {
          if (imageRef.current && hoverSrc) {
            imageRef.current.src = hoverSrc;
          }
        }}
        onMouseOut={() => {
          if (imageRef.current) {
            imageRef.current.src = src;
          }
        }}
        src={src}
        alt={alt}
        style={{
          aspectRatio: 160 / 215,
        }}
        //trick to fetch 3 first images ASAP to bring better UX while still keep performance good
        loading={index < 4 ? 'eager' : 'lazy'}
        className="w-full object-cover object-[center_top]"
      />
    </>
  );
};

export default CatalogProductImage;
