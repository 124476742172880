export const MAX_NUMBER_IMPRESSED_PRODUCTS_IN_STORE = 200;

/**
 *
 * @param store
 * @param sku
 *
 * This function is used to update the impressed products in the store.
 * It'll help us to avoid sending the same product multiple times to the server.
 */
export const updateImpressedProduct = (store: string[], sku: string) => {
  if (!store || store.includes(sku)) {
    return;
  }

  if (store.length >= MAX_NUMBER_IMPRESSED_PRODUCTS_IN_STORE) {
    store.shift();
  }

  store.push(sku);
};

export const throttleUpdateImpressedProduct = (store: string[], sku: string) => {
  //separate the logic from the main thread to avoid race condition
  setTimeout(() => updateImpressedProduct(store, sku), 0);
};
