import { ZDTProduct } from '@zalora/doraemon-ts';

const DEFAULT_LIMIT_SUGGESTION = 2;

export const isZSRData = (
  zeroSearchResults?: ZDTProduct.ZeroSearchResults | null,
): zeroSearchResults is ZDTProduct.ZeroSearchResults => {
  return (
    !!zeroSearchResults &&
    !!zeroSearchResults.OriginalSearchQuery &&
    !!zeroSearchResults.SuggestedSearchQuery
  );
};

export const getShowingSuggestions = (
  suggestions: ZDTProduct.ZeroSearchResultSuggestion[],
  currentSearchQuery: string,
  limit = DEFAULT_LIMIT_SUGGESTION,
) => {
  return suggestions.filter(({ Text }) => !!Text && Text !== currentSearchQuery).slice(0, limit);
};
