import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { trackViewedProducts } from 'pages/common.tracking';
import { SocialProofingMetricItem } from 'types/SocialProofing';
import { ZDTPromotion } from 'types/ZDT';
import {
  ProductListViewedItemLevelTrackingPayload,
  ProductListViewedTrackingPayload,
} from 'types/trackingPayload/ProductListTrackingPayload';
import { getSourceSegment } from 'utils/catalog-product';
import { getSourceCatalogListId } from 'utils/catalog/tracking';
import { sendGA4Event } from 'utils/gtm';
import * as productUtils from 'utils/product';
import * as SegmentTracking from 'utils/segment-analytic';
import { getCatalogSocialProofingDataBySku } from 'utils/socialProofing';
import { prepareProductTrackingDetails } from 'utils/tracking/common';
import { trackingImpression } from 'utils/tracking/zap';
import { getPosition } from '../catalog.tracking';
import { EventNames, SegmentData } from '../types';

export const trackingOnViewedProductInCatalog = ({
  product,
  index,
  segmentData,
  sourceCatalog,
  listId,
  promotions,
  scarcityProducts,
  // this value is only used for "Product List Viewed Item Level" event
  isImpressed,
}: {
  product: ZDTCatalog.Product | ZDTProduct.Product;
  index: number;
  segmentData: SegmentData;
  sourceCatalog?: string;
  listId?: string;
  promotions?: ZDTPromotion.PromotionMap['Promotions'];
  scarcityProducts?: Record<string, SocialProofingMetricItem[]>;
  isImpressed?: boolean;
}) => {
  const { AdId } = product;

  // Trigger sponsored event
  if (AdId) {
    trackingImpression(AdId);
  }

  const [defaultSourceCatalog, defaultListId] = getSourceCatalogListId(
    window.trackingData?.seoInfo,
  );
  const segmentFromBreadcrumbs = getSourceSegment(product);

  // track Product List Viewed
  // Trigger Segment event
  trackViewedProducts({
    viewedProduct: product,
    skuPosition: index,
    callback: (viewedProducts) => {
      const productList = viewedProducts.map((viewedProduct) => {
        const configSku = viewedProduct.ConfigSku || '';
        const socialProofingData = getCatalogSocialProofingDataBySku(configSku, scarcityProducts);

        return {
          seller_name: viewedProduct ? productUtils.getSellerName(viewedProduct) : '',
          ...prepareProductTrackingDetails(viewedProduct, promotions, socialProofingData),
        };
      });

      // event Product List Viewed
      const payload: ProductListViewedTrackingPayload = {
        source_segment: segmentFromBreadcrumbs || '',
        source_catalog: sourceCatalog || defaultSourceCatalog,
        list_id: listId || defaultListId,
        gridview_selected: true, // We only have gridview on Lotus
        pagination: (segmentData.currentPage || 1).toString(),
        pagination_upperbound: segmentData.numOfListedProducts || 0,
        products: productList,
        vendor_version_id: segmentData.vendor?.VersionId || undefined,
        vendor_source: segmentData.vendor?.Source || undefined,
      };

      SegmentTracking.track(EventNames.PRODUCT_LIST_VIEWED, payload);

      const ga4ProductList = viewedProducts.map((viewedProduct) => ({
        ...viewedProduct,
      }));

      sendGA4Event('view_item_list', ga4ProductList);
    },
  });

  if (isImpressed) {
    return;
  }

  // event Product List Viewed Item Level
  const configSku = product.ConfigSku || '';
  const socialProofingData = getCatalogSocialProofingDataBySku(configSku, scarcityProducts);

  const productData = {
    seller_name: product ? productUtils.getSellerName(product) : '',
    ...prepareProductTrackingDetails(product, promotions, socialProofingData),
  };

  const payload: ProductListViewedItemLevelTrackingPayload = {
    ...productData,
    ...getPosition(index),
    source_segment: segmentFromBreadcrumbs || '',
    source_catalog: sourceCatalog || defaultSourceCatalog,
    list_id: listId || defaultListId,
    gridview_selected: true, // We only have gridview on Lotus
    pagination: (segmentData.currentPage || 1).toString(),
    pagination_upperbound: segmentData.numOfListedProducts || 0,
    vendor_version_id: segmentData.vendor?.VersionId || undefined,
    vendor_source: segmentData.vendor?.Source || undefined,
  };

  SegmentTracking.track(EventNames.PRODUCT_LIST_VIEWED_ITEM_LEVEL, payload);
};
