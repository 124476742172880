import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { ExtendedZDTProductFilterOptions } from 'types/LDT';
import { PageInfo, isPIPPageInfo, isSearchPageInfo } from './catalog-page-type';
import { getFilterParams } from './filters';

export const getCategoriesFilterParams = (
  fieldId: string,
  value:
    | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)
    | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)[],
  pageInfo: PageInfo,
) => {
  const params = getFilterParams(fieldId, value);

  if (isSearchPageInfo(pageInfo)) {
    // on search page, we have to attach parent category id to the query param
    let firstOption: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions;

    if (Array.isArray(value)) {
      firstOption = value[0];
    } else {
      firstOption = value;
    }

    if ('Category' in firstOption && firstOption.Category) {
      params.parentCategoryId = firstOption.Category.Value || '';
    } else {
      params.parentCategoryId = '';
    }
  }

  return params;
};

export const shouldShowSegmentMenu = (pageInfo: PageInfo, filters?: ZDTCatalog.FilterResponse) => {
  if (!filters?.MenuSegments || isSearchPageInfo(pageInfo) || pageInfo.categoryId) {
    return false;
  }

  // Do not show segment on PIP if having category id
  // because every category belong to a segment already
  if (isPIPPageInfo(pageInfo) && !!pageInfo.productIndexInfo.Params?.Category?.length) {
    return false;
  }

  return true;
};
