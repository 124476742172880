import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';
import { TestIdsCommon } from 'constants/e2eIds/common-test-ids';

interface Props {}

const ZSRSkeleton: FC<Props> = () => {
  return (
    <div
      className="w-[300px]"
      data-test-id={TestIdsCommon.SKELETON}
    >
      <SkeletonItem height="h-5" />
      <SkeletonItem
        height="h-2"
        width="w-3/4"
        className="mt-1"
      />
    </div>
  );
};

export default ZSRSkeleton;
