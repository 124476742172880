import { ZDTInit } from 'types/ZDT';
import { Country } from './ventures';

type CurrencyConfiguration = {
  [countryCode in Country]: Required<ZDTInit.Currency>;
};

/**
 * Hard-coded currency configuration for each country. Based on
 * /v1/init response.
 */
const currencyConfiguration = Object.freeze<CurrencyConfiguration>({
  // Hong Kong
  ['hk']: {
    Decimals: 2,
    DecimalsSeparator: '.',
    DisplayFormat: 'HK$\xa0%s',
    Iso: 'HKD',
    ThousandSeparator: ',',
  },

  // Indonesia
  ['id']: {
    Decimals: 0,
    DecimalsSeparator: ',',
    DisplayFormat: 'Rp\xa0%s',
    Iso: 'IDR',
    ThousandSeparator: '.',
  },

  // Malaysia (& Brunei)
  ['my']: {
    Decimals: 2,
    DecimalsSeparator: '.',
    DisplayFormat: 'RM\xa0%s',
    Iso: 'MYR',
    ThousandSeparator: ',',
  },

  // Philippines
  ['ph']: {
    Decimals: 2,
    DecimalsSeparator: '.',
    DisplayFormat: 'Php\xa0%s',
    Iso: 'PHP',
    ThousandSeparator: ',',
  },

  // Singapore
  ['sg']: {
    Decimals: 2,
    DecimalsSeparator: '.',
    DisplayFormat: 'S$\xa0%s',
    Iso: 'SGD',
    ThousandSeparator: ',',
  },

  // Taiwan
  ['tw']: {
    Decimals: 0,
    DecimalsSeparator: '.',
    DisplayFormat: 'NT$\xa0%s',
    Iso: 'TWD',
    ThousandSeparator: ',',
  },
});

export default currencyConfiguration;
