import { QueryParams } from 'api/APIClient';
import {
  AVAILABLE_SEARCH_EVENTS,
  SEARCH_EVENTS,
  SEARCH_METHODS,
  SEARCH_METHOD_MAPPING,
  SEARCH_PARAMS,
} from 'constants/search';

const isSearchParam = (key: string) => SEARCH_PARAMS.includes(key);

/**
 * If all query params of the page are search params,
 * it is considered as an original search page
 */
export const isOriginalSearchPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchKeys = Array.from(searchParams.keys());

  return searchKeys.every(isSearchParam);
};

export const getSearchMethod = (queryParams: QueryParams) => {
  const searchMethod = String(queryParams.search_method);

  if (AVAILABLE_SEARCH_EVENTS.includes(searchMethod)) {
    return SEARCH_METHOD_MAPPING[searchMethod as SEARCH_EVENTS];
  }

  return SEARCH_METHODS.SUBMIT_SEARCH;
};
