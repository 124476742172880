const MILLIS_TO_WAIT_MODAL_CLOSED = 300;

export const scrollToElement = (id: string, delay = MILLIS_TO_WAIT_MODAL_CLOSED) => {
  const timeoutId: number = window.setTimeout(() => {
    const t = document.getElementById(id);

    if (!t) {
      return;
    }

    t.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, delay);

  return timeoutId;
};
