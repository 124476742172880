import React from 'react';
import FilterBarSkeleton from '../TopNav/FilterBarSkeleton';
import SegmentMenuSkeleton from '../TopNav/SegmentMenuSkeleton';

export const SkeletonTopNav = () => {
  return (
    <div className="desktop:hidden">
      <SegmentMenuSkeleton />
      <FilterBarSkeleton />
    </div>
  );
};
