import { FC } from 'react';
import { getCountryLogos } from 'utils/footer';

const CountriesList: FC = () => {
  const logos = getCountryLogos();

  // The component is always hidden, because it's only used for SEO purposes
  return (
    <div className="hidden">
      {logos.map(({ name, link, title }) => (
        <a
          key={name}
          href={link}
          title={title}
        />
      ))}
    </div>
  );
};

export default CountriesList;
