import Head from 'next/head';
import React from 'react';
import { getHrefLang } from 'utils/catalog/catalog-ads';

type Props = {
  hrefLangTag: Nullishable<string>;
};

export const HrefLang = ({ hrefLangTag }: Props) => {
  const hrefLang = getHrefLang(hrefLangTag);

  if (!hrefLang) {
    return null;
  }

  return (
    <Head>
      {Object.entries(hrefLang).map(([key, value]) => (
        <link
          key={key}
          href={value}
          hrefLang={key}
          rel="alternate"
        />
      ))}
    </Head>
  );
};
