import clsx from 'clsx';
import { FC } from 'react';
import { SocialProofingInfo } from 'components/SocialProofing';
import useCatalogScarcityProducts from 'hooks/api/scarcity/useCatalogScarcityProducts';
import { useDeviceType } from 'hooks/useDeviceType';
import { getCatalogSocialProofingDataBySku } from 'utils/socialProofing';

interface Props {
  configSku: string;
  isAllowedToShow: boolean;
}

const CatalogProductSocialProofingDetails: FC<Props> = ({ configSku, isAllowedToShow }) => {
  const { isMobile } = useDeviceType();
  const { data: scarcities, isLoading, isValidating } = useCatalogScarcityProducts();

  // Only show 1 social proofing message on catalog pages to reduce mental load.
  const socialProofingData = getCatalogSocialProofingDataBySku(configSku, scarcities);

  const isScarcitiesLoading = isLoading || isValidating;

  if (isScarcitiesLoading && isAllowedToShow) {
    return (
      <div
        className={clsx('mt-2 rounded bg-grey-10', {
          'h-6 w-2/3': !isMobile,
          'h-8': isMobile,
        })}
      ></div>
    );
  }

  if (!isScarcitiesLoading && !isAllowedToShow) {
    return null;
  }

  return (
    <SocialProofingInfo
      socialProofingData={socialProofingData}
      isMobile={isMobile}
      isCatalogPage
    />
  );
};

export default CatalogProductSocialProofingDetails;
