import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

const TrackProductListFiltered = dynamic(() => import('./TrackProductListFiltered'), {
  ssr: false,
});

const TrackProductListSorted = dynamic(() => import('./TrackProductListSorted'), {
  ssr: false,
});

const TrackNoResultReturned = dynamic(() => import('./TrackNoResultReturned'), {
  ssr: false,
});

const withCatalogTracking = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const CatalogTrackingWrapper = (props: P) => {
    return (
      <>
        <TrackProductListFiltered />
        <TrackProductListSorted />
        <TrackNoResultReturned />

        <WrappedComponent {...props} />
      </>
    );
  };

  return CatalogTrackingWrapper;
};

export default withCatalogTracking;
