import { useMemo } from 'react';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';
import useUser from './api/useUser';

const useSocialProofing = (configSku: string) => {
  const { data: user } = useUser();
  const isEnabled = useOptimizelyStore(optimizelySelector.isSocialProofingEnabled) ?? false;

  const userOrderCount = user?.OrderCount || 0;

  const isAllowedToShow = useMemo(
    () => !!configSku && userOrderCount === 0,
    [configSku, userOrderCount],
  );

  return { isEnabled, isAllowedToShow };
};

export default useSocialProofing;
