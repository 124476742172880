import clsx from 'clsx';
import React from 'react';
import { SkeletonItem } from 'components/skeleton';

export const SkeletonCatalogDetails = () => {
  return (
    <div className="flex h-4 items-center justify-between tablet:h-[26px] desktop:h-9">
      <div
        className={clsx(
          'flex flex-1 items-center justify-between gap-4',
          'tablet:justify-center',
          'desktop:justify-start',
        )}
      >
        <SkeletonItem
          width="w-48"
          height="h-8"
        />
        <SkeletonItem width="w-24 h-8" />
      </div>

      <SkeletonItem
        width="w-20"
        height="h-8"
        className="hidden desktop:block"
      />
    </div>
  );
};
