import clsx from 'clsx';
import { FC } from 'react';
import { SocialProofingMetricItem } from 'types/SocialProofing';
import { isNonEmptyArray } from 'utils/array';
import SocialProofingWrapper from './SocialProofingWrapper';

interface Props {
  isCatalogPage?: boolean;
  isMobile: boolean;
  socialProofingData: SocialProofingMetricItem[];
}

const SocialProofingInfo: FC<Props> = ({ isCatalogPage = false, isMobile, socialProofingData }) => {
  if (!isNonEmptyArray(socialProofingData)) {
    return null;
  }

  return (
    <div
      data-test-id="socialProofingInfo"
      className={clsx('text-grey-60', {
        'mb-2 mt-3 border-y py-1': !isCatalogPage,
        'mt-2': isCatalogPage,
        'w-full': isMobile,
        'w-fit': !isMobile,
      })}
    >
      <div className={clsx('flex flex-wrap', { 'flex-col': isCatalogPage })}>
        {socialProofingData.map(({ count, label, duration }) => (
          <SocialProofingWrapper
            key={label}
            count={count}
            label={label}
            duration={duration}
            isCatalogPage={isCatalogPage}
          />
        ))}
      </div>
    </div>
  );
};

export default SocialProofingInfo;
