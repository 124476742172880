import { ZDTProduct } from '@zalora/doraemon-ts';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { StoreItem } from './StoreItem';

interface MoreStoresProps {
  storeEntry?: Nullable<ZDTProduct.ProductStoreEntry>;
}

const MoreStores: FC<MoreStoresProps> = ({ storeEntry }) => {
  const { t } = useTranslation('catalog');

  if (!storeEntry) {
    return null;
  }

  const { Stores } = storeEntry;
  const backgroundImageUrl = storeEntry.BackgroundImageUrl || '';

  if (!Stores || Stores.length === 0) {
    return null;
  }

  const isOneStore = Stores.length === 1;

  return (
    <div className="mb-5 flex flex-col tablet:flex-row tablet:items-center tablet:justify-center desktop:justify-start">
      <p className="mb-1 mr-4 whitespace-nowrap font-medium desktop:m-0 desktop:mr-4">
        {isOneStore ? t('Visit store') : t('More Stores')}
      </p>
      <div className="no-scrollbar flex flex-grow-0 overflow-scroll">
        {isOneStore ? (
          <StoreItem
            store={Stores[0]}
            backgroundUrl={backgroundImageUrl}
          />
        ) : (
          Stores.map((store) => (
            <StoreItem
              key={store.SellerId}
              store={store}
              backgroundUrl={backgroundImageUrl}
            />
          ))
        )}
      </div>
    </div>
  );
};

export { MoreStores };
