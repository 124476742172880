export interface PriceFilterInputs extends Record<string, string> {
  minPrice: string;
  maxPrice: string;
}

export const FILTER_ALL = '*';

export const isDigits = (value: string) => {
  if (/[^*\d]/.test(value)) {
    return false;
  }

  return true;
};

export const isRangeValid = (_: string, { minPrice, maxPrice }: PriceFilterInputs) => {
  if (minPrice && maxPrice && Number(minPrice) >= Number(maxPrice)) {
    return false;
  }

  return true;
};

export const isEmptyFormData = (
  formData: Partial<PriceFilterInputs>,
): formData is PriceFilterInputs => {
  if (!formData) {
    return true;
  }

  return Object.values(formData).every((val) => !val || val === FILTER_ALL);
};

export const getDisplayedPrice = (defaultValue: string | undefined) => {
  if (!defaultValue || defaultValue === FILTER_ALL) {
    return '';
  }

  return defaultValue;
};
