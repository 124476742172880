import Link from 'next/link';
import { FC } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { Robots } from 'utils/catalog/types';
import { getCountryCode } from 'utils/countries';
import DarkFooterLink from './DarkFooterLink';

const DarkFooter: FC = () => {
  const footerContent = useUiStore(uiSelectors.footerContent);
  const { t } = useCustomTranslation('footer');

  if (!footerContent) {
    return null;
  }

  const {
    csLeft = [],
    csRight = [],
    auLeft = [],
    auRight = [],
    findUsOn = [],
    apps = [],
    supportUrl,
  } = footerContent;

  // The component is always hidden, because it's only used for SEO purposes
  return (
    <div className="hidden">
      <DarkFooterLink links={csLeft} />
      <DarkFooterLink links={csRight} />
      <DarkFooterLink links={auLeft} />
      <DarkFooterLink links={auRight} />

      {findUsOn.map((item) => {
        const { url, label = '' } = item;

        return (
          <a
            key={url}
            href={url}
            aria-label={label}
            title={label}
          />
        );
      })}

      {apps.map(({ key }) => (
        <a
          key={key}
          href="/mobile-apps"
          title="Mobile Apps"
        />
      ))}

      {getCountryCode() !== 'tw' ? (
        <Link
          href="/about/"
          passHref
          prefetch={false}
        >
          {t('About')}
        </Link>
      ) : null}

      <Link
        href="/privacy-policy/"
        passHref
        prefetch={false}
      >
        {t('Privacy')}
      </Link>

      <Link
        href="/terms-of-use/"
        passHref
        prefetch={false}
      >
        {t('Terms of Service')}
      </Link>

      <Link
        href="/contact/"
        passHref
        prefetch={false}
      >
        {t('Contact')}
      </Link>
      <a
        href={supportUrl}
        rel={Robots.NOINDEX_NOFOLLOW}
      >
        {t('Help')}
      </a>
    </div>
  );
};

export default DarkFooter;
