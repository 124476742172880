import clsx from 'clsx';
import { FC } from 'react';
import MembershipPurchaseBannerSkeleton from 'components/MembershipPurchaseBanner/MembershipPurchaseBannerSkeleton';
import { SkeletonItem, SkeletonProduct } from 'components/skeleton';
import FilterContentSkeleton from '../Filter/FilterContent/FilterContentSkeleton';
import { SkeletonCatalogDetails } from './SkeletonCatalogDetails';
import { SkeletonTopNav } from './SkeletonTopNav';

interface Props {
  shouldHideBreadcrumbs?: boolean;
}

export const CatalogSkeleton: FC<Props> = ({ shouldHideBreadcrumbs }) => {
  return (
    <div className="animate-pulse">
      {/* Breadcrumbs */}
      {shouldHideBreadcrumbs ? null : (
        <SkeletonItem
          height="h-5"
          width="w-[300px]"
          className="mb-5 hidden h-3.5 w-80 desktop:block"
        />
      )}
      {/* Top Nav */}
      <SkeletonTopNav />

      <div className="flex flex-col gap-5 p-4 desktop:flex-row desktop:p-0">
        {/* Filter */}
        <FilterContentSkeleton />

        <div className="flex-1">
          {/* Catalog Details */}
          <SkeletonCatalogDetails />

          {/* Membership Banner */}
          <MembershipPurchaseBannerSkeleton className="mt-6" />

          {/* Products */}
          <div
            className={clsx(
              'mt-6',
              'grid grid-cols-2 gap-2',
              'tablet:grid-cols-4 tablet:gap-8',
              'desktop:grid-cols-3 desktop:gap-6',
            )}
          >
            {[...Array(12).keys()].map((_, idx) => (
              <SkeletonProduct key={idx} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
