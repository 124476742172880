import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { useRouter } from 'next/router';
import {
  createContext,
  FC,
  PropsWithChildren,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryParams } from 'api/APIClient';
import { SortParams } from 'components/catalog/Sort/SortSelection';
import { CatalogFilterName } from 'constants/catalog';
import useCatalogFilters from 'hooks/catalog/useCatalogFilters';
import useCatalogProducts from 'hooks/catalog/useCatalogProducts';
import useCategoryFilters from 'hooks/catalog/useCategoryFilters';
import { ExtendedZDTProductFilterOptions } from 'types/LDT';
import { getAppliedFilters, RefinedFilter } from 'utils/catalog/applied-filter';
import { getCategoriesFilterParams } from 'utils/catalog/category';
import { getFilterParams } from 'utils/catalog/filters';
import { getCatalogQuery, getSearchTerm } from 'utils/catalog/query';
import { scrollToElement } from 'utils/catalog/scroll-to-element';
import { getSkuList } from 'utils/product';
import setLowPriorityTask from 'utils/setLowPriorityTask';
import { useStaticCatalogContext } from './StaticCatalogContext';

interface DynamicCatalogContextValue {
  appliedFilters: RefinedFilter[];
  categoryFilter: Nullishable<ZDTProduct.Filter>;

  data: {
    filters: ZDTCatalog.FilterResponse | undefined;
    products: ZDTCatalog.ProductResponse | undefined;
    sponsoredProducts: ZDTProduct.ProductList | undefined;
  };
  isLoadingProducts: boolean;
  error?: unknown;
  configSkus: string[];

  // TODO: split to filter context
  filterLocation: string | undefined;
  hasFetchedCategoryFilters: boolean;
  hasFetchedFilters: boolean;
  isLoadingCategoryFilters: boolean;
  isLoadingFilters: boolean;
  menuCategories: Nullishable<ZDTProduct.FilterOptions[]>;
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>;
  setFilterLocation: (filterLocation: string) => void;
  handleClearAllFilters: () => void;
  handleFilterChange: (
    filterId: string | undefined,
    value:
      | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)
      | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)[],
    appliedFilterLocation?: string,
  ) => void;
  handleSortChange: (sortParams: SortParams) => void;
}

const DynamicCatalogContext = createContext<DynamicCatalogContextValue>({} as never);

export const useDynamicCatalogContext = () => useContext(DynamicCatalogContext);

export const DynamicCatalogContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pageInfo, params, isSearchCrawler } = useStaticCatalogContext();
  // Fetch products
  const {
    products,
    sponsoredProducts,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useCatalogProducts({
    pageInfo,
    params,
    isSearchCrawler,
  });

  const [shouldFetchFilters, setShouldFetchFilters] = useState(false);

  // Fetch catalog filters
  const {
    filters,
    hasFetched: hasFetchedFilters,
    isLoading: isLoadingFilters,
    error: errorFilters,
  } = useCatalogFilters({
    pageInfo,
    params,
    shouldFetch: shouldFetchFilters,
    isSearchCrawler,
  });

  // Fetch category filters
  const {
    categoryFilter,
    hasFetched: hasFetchedCategoryFilters,
    isLoading: isLoadingCategoryFilters,
    menuCategories,
    menuSegments,
  } = useCategoryFilters({
    pageInfo,
    params,
    shouldFetch: isSearchCrawler || shouldFetchFilters,
    isSearchCrawler,
  });

  const configSkus = useMemo(() => {
    const { Products = [] } = products || {};
    const { Products: SponsoredProducts = [] } = sponsoredProducts || {};
    const sponsoredSkus = getSkuList(SponsoredProducts || []);
    const normalSkus = getSkuList(Products || []);

    return [...sponsoredSkus, ...normalSkus];
  }, [products, sponsoredProducts]);

  const { ZeroSearchResults } = products || {};
  const { SuggestedSearchQuery } = ZeroSearchResults || {};

  const appliedFilters = useMemo(
    () => getAppliedFilters(filters?.Filters, categoryFilter, params, pageInfo),
    [filters?.Filters, categoryFilter, params, pageInfo],
  );

  const searchTerm = getSearchTerm(params.query, SuggestedSearchQuery || undefined);
  const router = useRouter();
  const [filterLocation, setFilterLocation] = useState<string | undefined>();

  const handleFilterChange = useCallback(
    (
      fieldId: string | undefined,
      value:
        | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)
        | (ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions)[],
      appliedFilterLocation?: string,
    ) => {
      if (!fieldId) {
        return;
      }

      const query = router.query;
      const newFilters =
        fieldId === CatalogFilterName.CATEGORY
          ? getCategoriesFilterParams(fieldId, value, pageInfo)
          : getFilterParams(fieldId, value);

      setLowPriorityTask(() => {
        setFilterLocation(appliedFilterLocation);

        router.push(
          {
            pathname: window.location.pathname,
            query: getCatalogQuery({ ...query, ...newFilters, q: searchTerm } as QueryParams, true),
          },
          undefined,
          { shallow: true },
        );
        scrollToElement('section__page-title');
      });
    },
    [router, pageInfo, searchTerm],
  );

  const handleSortChange = useCallback(
    (sortParams: SortParams) => {
      scrollToElement('section__page-title');

      router.push(
        {
          pathname: window.location.pathname,
          query: getCatalogQuery(
            {
              ...router.query,
              ...sortParams,
              q: searchTerm,
            } as QueryParams,
            true,
          ),
        },
        undefined,
        { shallow: true },
      );
    },
    [router, searchTerm],
  );

  const handleClearAllFilters = useCallback(() => {
    const query = router.query.q;

    scrollToElement('section__page-title');

    router.push(
      {
        pathname: window.location.pathname,
        query: query ? { q: query } : {},
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  const contextValue: DynamicCatalogContextValue = {
    appliedFilters,
    categoryFilter,
    configSkus,
    data: {
      filters,
      products,
      sponsoredProducts,
    },
    filterLocation,
    hasFetchedCategoryFilters,
    hasFetchedFilters,
    isLoadingCategoryFilters,
    isLoadingFilters,
    isLoadingProducts,
    error: errorProducts || errorFilters,
    menuCategories,
    menuSegments,

    // Actions
    setFilterLocation,
    handleClearAllFilters,
    handleFilterChange,
    handleSortChange,
  };

  useEffect(() => {
    // We need defer `filter` API call to prioritize `list` API call
    // however, we dont expect it's fetched after `list` API is done,
    // this affects to `speed index` metric because we have to wait for `filter` API call to paint UI fully
    startTransition(() => {
      setShouldFetchFilters(true);
    });
  }, []);

  return (
    <DynamicCatalogContext.Provider value={contextValue}>{children}</DynamicCatalogContext.Provider>
  );
};
