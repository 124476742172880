import { Pagination as ZuiPagination } from '@zalora/zui';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ComponentProps, useCallback, useState } from 'react';
import { TestIdsPagination } from 'constants/e2eIds/pagination-test-ids';
import { setQueryParams } from 'utils/url';

const DataTestIdByPageType = {
  next: TestIdsPagination.NEXT_LINK,
  previous: TestIdsPagination.PREVIOUS_LINK,
  number: TestIdsPagination.NUMBER_LINK,
};

export const LinkPagination = ({
  onPageChange,
  ...paginationProps
}: Omit<ComponentProps<typeof ZuiPagination>, 'labels' | 'aria'>) => {
  const router = useRouter();
  const { t } = useTranslation();

  // Internal state to avoid flicking when page in query changes
  const [activePage, setActivePage] = useState(Number(router.query.page || 1));

  const handlePageChange = useCallback(
    (page: number) => {
      if (typeof onPageChange === 'function') {
        onPageChange(page);
      }

      setActivePage(page);
    },
    [onPageChange],
  );

  return (
    <ZuiPagination
      aria={{
        paginationLabel: t('Pagination controls'),
        nextButtonLabel: t('Navigate to the next page'),
        previousButtonLabel: t('Navigate to the previous page'),
      }}
      labels={{
        nextButton: t('Next'),
        previousButton: t('Previous'),
      }}
      activePage={activePage}
      onPageChange={handlePageChange}
      adjacentClassName="hidden tablet:block"
      shouldNotHighlightActivePage={false}
      Item={({ disabled, pageNumber, children, pageType, ...props }) => {
        return disabled ? (
          <button
            disabled={disabled}
            {...props}
          >
            {children}
          </button>
        ) : (
          <Link
            href={setQueryParams(router.asPath, {
              page: pageNumber.toString(),
            })}
            shallow
            {...props}
            data-test-id={DataTestIdByPageType[pageType]}
            data-selected={activePage === pageNumber ? true : undefined}
          >
            {children}
          </Link>
        );
      }}
      {...paginationProps}
    />
  );
};
