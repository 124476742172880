import { SVGProps } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC, ReactElement } from 'react';

interface Props {
  className?: string;
  innerClassName?: string;
  icon: ReactElement<SVGProps>;
  message: ReactElement;
}

const SocialProofingItem: FC<Props> = ({ className, innerClassName, icon, message }) => {
  return (
    <div
      data-test-id="socialProofingItem"
      className={clsx('flex', className)}
    >
      {icon}
      <div className={clsx('w-full break-all', innerClassName)}>{message}</div>
    </div>
  );
};

export default SocialProofingItem;
