import dynamic from 'next/dynamic';
import { FC, PropsWithChildren, Suspense, lazy } from 'react';
import Header from 'components/Layout/ResponsiveHeader';
import DesktopFooter from './DesktopLayout/DesktopFooter';
import { UspBarSkeleton } from './DesktopLayout/UspBar/UspBarSkeleton';
import CountriesList from './Footer/CountriesList/CountriesList';
import DarkFooter from './Footer/DarkFooter/DarkFooter';
import AppSmartBannerSkeleton from './MobileLayout/AppSmartBanner/AppSmartBannerSkeleton';
import MobileFooter from './MobileLayout/MobileFooter';

const LayoutAsyncSection = lazy(() => import('./LayoutAsyncSection'));
const BottomNav = dynamic(() => import('./MobileLayout/BottomNav/BottomNav'), { ssr: false });

interface Props extends PropsWithChildren {
  shouldShownBackToTop?: boolean;
  shouldShowMobileFooter?: boolean;
}

const Layout: FC<Props> = ({ children, shouldShownBackToTop, shouldShowMobileFooter = true }) => {
  return (
    <>
      <Suspense
        fallback={
          <>
            <AppSmartBannerSkeleton />
            <UspBarSkeleton />
          </>
        }
      >
        <LayoutAsyncSection shouldShownBackToTop={shouldShownBackToTop} />
      </Suspense>

      <div className="flex min-h-0 flex-col">
        <Header />
        <main className="w-full shrink grow desktop:pb-0">{children}</main>
        <DesktopFooter className="hidden desktop:flex" />
        {shouldShowMobileFooter ? <MobileFooter /> : null}
        <div className="pt-12 desktop:hidden">
          <BottomNav />
        </div>

        {/* Always render for SEO purposes but hidden */}
        <CountriesList />
        <DarkFooter />
      </div>
    </>
  );
};

export default Layout;
