import { ZDTProduct } from '@zalora/doraemon-ts';
import { Button } from '@zalora/zui';
import { SizeFilterIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useMemo, useRef } from 'react';
import { CatalogFilterName } from 'constants/catalog';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import { PRODUCT_LIST_FILTER_LOCATION } from 'constants/tracking';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import { trackingOnMidCatalogFilterViewed } from 'pages/catalog/catalog.tracking';
import { getQuickSizeFilterOptions } from 'utils/filters/sizeFilter';
import { setQueryParams } from 'utils/url';

interface Props {
  className?: string;
}

const QuickSizeFilter: FC<Props> = ({ className }) => {
  const elQuickSizeFilter = useRef<HTMLDivElement>(null);
  const { data, setFilterLocation } = useDynamicCatalogContext();
  const { params } = useStaticCatalogContext();
  const router = useRouter();
  const { t } = useTranslation('catalog');
  const { filters } = data;
  const shouldShowQuickSizeFilter = filters && !params[CatalogFilterName.SIZE];

  const sizeButtons = useMemo(
    () => (shouldShowQuickSizeFilter ? getQuickSizeFilterOptions(filters) : []),
    [shouldShowQuickSizeFilter, filters],
  );

  const entry = useIntersectionObserver(elQuickSizeFilter, { freezeOnceVisible: true });
  const isIntersecting = !!entry?.isIntersecting;

  useInvokeCallbackOnceWhen(() => {
    trackingOnMidCatalogFilterViewed(data, params);
  }, isIntersecting);

  const handleClickFilterOption = ({
    minOption,
    maxOption,
  }: {
    minOption: ZDTProduct.FilterOptions;
    maxOption: ZDTProduct.FilterOptions;
  }) => {
    // Set filter location for the Segment event before
    // adding filters (event will be triggered after page navigation).
    setFilterLocation(PRODUCT_LIST_FILTER_LOCATION.MID_CATALOG);

    router.push(
      setQueryParams(
        router.asPath,
        {
          'sizeSystems[]': [minOption.Label || '', maxOption.Label || ''],
        },
        ['page'],
      ),
      undefined,
      { shallow: true },
    );
  };

  if (sizeButtons.length === 0) {
    return null;
  }

  return (
    <div
      ref={elQuickSizeFilter}
      className={clsx('bg-grey-10 px-[30px] py-4', className)}
    >
      <div className="flex flex-col items-center gap-y-3">
        <div className="flex items-center gap-x-2.5 p-2.5">
          <SizeFilterIcon />
          <span className="text-sm font-bold">{t('Filter by size')}</span>
        </div>

        <div className="flex flex-wrap justify-center gap-2.5">
          {sizeButtons.map((opt) => (
            <Button
              key={opt.text}
              onClick={() => handleClickFilterOption(opt)}
              variant="outline"
              size="sm"
              className="whitespace-nowrap rounded-lg px-4 py-2"
              data-test-id={TestIdsCatalog.QUICK_SIZE_FILTER_BUTTON}
              data-value={opt.text}
            >
              {opt.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickSizeFilter;
