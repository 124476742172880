import { ZDTProduct, ZDTSEO } from '@zalora/doraemon-ts';
import { FILTER_ALL_SEGMENT_OPTION } from 'constants/segments';
import { sortCategoryByDepth } from 'utils/catalog/breadcrumbs';
import { buildCategoryUrl, buildSubCategoryUrl } from 'utils/catalog/build-category-url';
import { buildSegmentUrl } from 'utils/catalog/build-segment-url';
import { CatalogType, PageInfo } from 'utils/catalog/catalog-page-type';

export const getInternalLinkOptions = ({
  pageInfo,
  menuSegments,
  menuCategories,
  categoryFilter,
  asPath,
}: {
  pageInfo: PageInfo;
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>;
  menuCategories: Nullishable<ZDTProduct.FilterOptions[]>;
  categoryFilter: Nullishable<ZDTProduct.Filter>;
  asPath: string;
}) => {
  if (pageInfo.catalogType === CatalogType.SEARCH) {
    return [];
  }

  // segment
  if (!pageInfo.segment) {
    return menuSegments
      ?.filter((item) => item.ResultCount && item.Value !== FILTER_ALL_SEGMENT_OPTION)
      .map((item) => ({
        label: item.Label,
        url: buildSegmentUrl(item.Value || '', pageInfo, asPath),
      }));
  }

  const pathName = asPath.split('?')[0];

  // root cat
  if (!pageInfo.categoryId) {
    return menuCategories
      ?.filter((item) => item.ResultCount)
      .map((item) => ({
        label: item.Label,
        url: buildCategoryUrl({ pageInfo, category: item, pathName }),
      }));
  }

  // sub cat
  return categoryFilter?.Options?.filter((item) => item.ResultCount).map((item) => ({
    label: item.Label,
    url: buildSubCategoryUrl({ categoryItem: item, pageInfo, pathName }),
  }));
};

export const getInternalLinkTitle = (seoInfo: ZDTSEO.CatalogInfo) => {
  const { CategoryBreadcrumbs, Brand } = seoInfo.PageInfo || {};
  const defaultName = Brand?.Name || '';

  if (!CategoryBreadcrumbs?.length) {
    return defaultName;
  }

  const sortedBreadcrumbs = sortCategoryByDepth(CategoryBreadcrumbs);
  const currentCategory = sortedBreadcrumbs[sortedBreadcrumbs.length - 1];

  return currentCategory?.Name || defaultName;
};
