import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { CatalogFilterName } from 'constants/catalog';
import { getFilterByType, removeDuplicatedFilterOptions } from 'utils/catalog/filters';

const INTL_REGEXP = /^International_/;

export const parseSizeFilter = (
  filter: ZDTProduct.Filter,
  selectedSizeSystem: string | undefined,
) => {
  if (!filter.Options) {
    return {};
  }

  const selectedIndexes = new Set<number>();

  const sizeGroupBySizeSystem = filter.Options.reduce(
    (previous, option) => {
      if (!option.Value) {
        return previous;
      }

      const [sizeSystem] = option.Value.split('_');
      const newOptions = [...(previous[sizeSystem] || []), option];

      if (option.Selected && (!selectedSizeSystem || sizeSystem === selectedSizeSystem)) {
        // Only count selected index for selected size system
        selectedIndexes.add(newOptions.length - 1);
      }

      return {
        ...previous,
        [sizeSystem]: [...(previous[sizeSystem] || []), option],
      };
    },
    {} as Record<string, ZDTProduct.FilterOptions[]>,
  );

  return {
    selectedSizeIndexes: Array.from(selectedIndexes),
    sizeGroupBySizeSystem,
  };
};

export const getSizeList = (
  sizeGroupBySizeSystem: Record<string, ZDTProduct.FilterOptions[]> | undefined,
  sizeSystem: string,
) => {
  if (!sizeGroupBySizeSystem) {
    return [];
  }

  return sizeGroupBySizeSystem[sizeSystem];
};

export const getSelectedSizesByIndex = (
  sizeListBySizeSystem: Record<string, ZDTProduct.FilterOptions[]>,
  selectedSizeIndexes: number[],
  selectedSizeSystemName: string,
) => {
  return Object.entries(sizeListBySizeSystem).reduce((acc, [sizeSystem, sizeList]) => {
    const isSizeSystemSelected = sizeSystem === selectedSizeSystemName;
    const selectedSizes = sizeList.filter((_, index) => selectedSizeIndexes.includes(index));

    // set selected size system to the first position
    return isSizeSystemSelected ? [...selectedSizes, ...acc] : [...acc, ...selectedSizes];
  }, [] as ZDTProduct.FilterOptions[]);
};

export const getInternationalSizeFilterOptions = (filterOptions: ZDTProduct.FilterOptions[]) => {
  const intlSizeOptions = filterOptions.filter(({ Label }) => INTL_REGEXP.test(Label || ''));

  return removeDuplicatedFilterOptions(intlSizeOptions);
};

export const getQuickSizeFilterOptions = (filters: ZDTCatalog.FilterResponse) => {
  const filterByType = getFilterByType(filters, CatalogFilterName.SIZE);

  if (!filterByType || !filterByType.Options) {
    return [];
  }

  const intlSizeOptions = getInternationalSizeFilterOptions(filterByType.Options);
  const hasEnoughSizeOptions = intlSizeOptions.length > 1;

  if (!hasEnoughSizeOptions) {
    return [];
  }

  // Create button pairs, e.g. [XS-S], [S-M], [M-L], etc.
  const quickSizeFilterOptions = intlSizeOptions.reduce(
    (
      buttons: {
        text: string;
        minOption: ZDTProduct.FilterOptions;
        maxOption: ZDTProduct.FilterOptions;
      }[],
      option,
      idx,
    ) => {
      if (idx === intlSizeOptions.length - 1) {
        return buttons;
      }

      const minOption = option;
      const maxOption = intlSizeOptions[idx + 1];

      const minOptionText = minOption.Value?.replace(INTL_REGEXP, '') || '';
      const maxOptionText = maxOption.Value?.replace(INTL_REGEXP, '') || '';

      buttons.push({
        text: `${minOptionText}-${maxOptionText}`,
        minOption,
        maxOption,
      });

      return buttons;
    },
    [],
  );

  return quickSizeFilterOptions;
};

/**
 * By default, the first size in params is the selected size system.
 */
export const getSelectedSizeSystemFromQuery = (query: QueryParams | undefined) => {
  const sizeParams = query?.[CatalogFilterName.SIZE];

  if (!sizeParams) {
    return undefined;
  }

  const firstParam = Array.isArray(sizeParams) ? sizeParams[0] : sizeParams;

  if (typeof firstParam !== 'string') {
    return undefined;
  }

  const [sizeSystem] = firstParam.split('_');

  return sizeSystem;
};
