import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import React, { FC } from 'react';
import { getCountryCode } from 'utils/countries';

export const AppInfo: FC = () => {
  const { t } = useTranslation();

  const countryCode = getCountryCode()?.toLowerCase();
  const deeplink = `zalora://${countryCode}/seg_s/m`;

  return (
    <Head>
      {/* Android */}
      <meta
        name="google-play-app"
        content="app-id=com.zalora.android"
      />

      <meta
        property="al:android:package"
        content="com.zalora.android"
      />

      <meta
        property="al:android:app_name"
        content={t('androidAppName')}
      />

      <meta
        property="al:android:url"
        content={deeplink}
      />

      {/* iOS */}
      <meta
        property="al:ios:app_store_id"
        content="624639017"
      />

      <meta
        property="al:ios:app_name"
        content={t('iosAppName')}
      />

      <meta
        property="al:ios:url"
        content={deeplink}
      />
    </Head>
  );
};
