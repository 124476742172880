import { FILTER_ALL_SEGMENT_OPTION } from 'constants/segments';
import { setQueryParams } from 'utils/url';
import { PageInfo, isPIPPageInfo, isZISPageInfo } from './catalog-page-type';
import { getPersistingParams } from './filters';

export const buildSegmentUrl = (
  segment: string | undefined,
  pageInfo: PageInfo,
  currentUrl: string,
) => {
  if (isZISPageInfo(pageInfo) || isPIPPageInfo(pageInfo)) {
    const generatedUrl = setQueryParams(currentUrl, { segment });

    return generatedUrl;
  }

  let url = `/c`;
  const [pathname, queryString] = currentUrl.split('?');
  let cleanPathname = pathname.replace(/^\/c/, '');

  const persistingParams = getPersistingParams(queryString);

  if (segment && segment !== FILTER_ALL_SEGMENT_OPTION) {
    url += `/${segment}`;
  }

  if (pageInfo.segment) {
    // if the current page has segment, then remove it from the url
    // will concat the new segment on the next line
    cleanPathname = cleanPathname.replace(`/${pageInfo.segment}`, '');
  }

  if (cleanPathname !== '/search') {
    url += cleanPathname;
  }

  if (persistingParams !== '') {
    url += `?${persistingParams}`;
  }

  return url;
};
