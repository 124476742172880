import dynamic from 'next/dynamic';
import { FC, memo } from 'react';
import { useDeviceType } from 'hooks/useDeviceType';
import { SkeletonTopNav } from '../CatalogSkeleton/SkeletonTopNav';

const TopNav = dynamic(() => import('./TopNav'), {
  ssr: false,
  loading: () => <SkeletonTopNav />,
});

const TopNavWrapper: FC = () => {
  const { isMobile, isTablet } = useDeviceType();
  const showTopNav = isMobile || isTablet;

  if (!showTopNav) {
    // return null will create layout shift if SSR
    return <SkeletonTopNav />;
  }

  return <TopNav />;
};

export default memo(TopNavWrapper);
