import { useDynamicCatalogContext } from 'context/CatalogContext';
import useSWRImmutable from 'swr/immutable';
import useSocialProofing from 'hooks/useSocialProofing';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';
import { getCatalogProductsScarcityData } from 'utils/socialProofing';

const useCatalogScarcityProducts = () => {
  const { configSkus } = useDynamicCatalogContext();
  const { isEnabled, isAllowedToShow } = useSocialProofing(configSkus.join(','));
  const isAbTestEnabled = useOptimizelyStore(optimizelySelector.isSocialProofingEnabled);

  const shouldFetchData = isEnabled && isAbTestEnabled && isAllowedToShow;

  const scarcityData = useSWRImmutable(
    shouldFetchData ? ['/v1/products/scarcity', configSkus] : null,
    () => getCatalogProductsScarcityData(configSkus),
  );

  return scarcityData;
};

export default useCatalogScarcityProducts;
