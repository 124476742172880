import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { Card } from 'components/Card';
import { SkeletonFilter } from 'components/catalog/CatalogSkeleton/SkeletonFilter';
import { SkeletonItem } from 'components/skeleton/SkeletonItem';

const FilterContentSkeleton: FC = () => {
  return (
    <div
      className={clsx(
        'hidden h-max max-h-max min-h-0 w-[320px] rounded-2xl p-5 pt-8',
        'desktop:flex desktop:basis-auto desktop:flex-col desktop:gap-4',
      )}
    >
      {[...Array(3).keys()].map((_, idx) => (
        <Fragment key={idx}>
          <Card className="flex flex-col gap-4">
            <SkeletonItem width="w-20" />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </Card>
          <SkeletonFilter />
        </Fragment>
      ))}
    </div>
  );
};

export default FilterContentSkeleton;
