import { useDynamicCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { PER_PAGE } from 'constants/catalog';
import { getCatalogRedirectionLink } from 'utils/catalog/get-redirection-url';

export const useCatalogRedirectIfNeeded = () => {
  const router = useRouter();
  const { data, isLoadingProducts } = useDynamicCatalogContext();
  const { products } = data;

  useEffect(() => {
    if (isLoadingProducts) {
      return;
    }

    const totalPages =
      products && products.NumProductFound ? Math.ceil(products.NumProductFound / PER_PAGE) : 0;
    const currentPage = Number(router.query.page || 1);

    // need to check totalPages is non-zero
    // because if there is no product found, we should not redirect and show no results page
    if (totalPages && currentPage > totalPages) {
      const updatedUrl = getCatalogRedirectionLink(router.asPath);

      router.replace(updatedUrl, undefined, { shallow: true });
    }
  }, [isLoadingProducts, products, router]);
};
