import {
  ALIPAY_HK,
  AMERICAN_EXPRESS,
  ATOME,
  BANK_CENTRAL_ASIA,
  BANK_MANDIRI,
  BANK_NEGARA_ID,
  BANK_RAKYAT_ID,
  BLITZ,
  CASH_ON_DELIVERY,
  CIMB,
  CITIBANK,
  CITY_LINK,
  CTBC,
  DBS,
  DBS_PAYLAH,
  ENTREGO,
  FUBON,
  GDEX,
  GOJEK,
  GOOGLE_PAY,
  GOPAY,
  ID_EXPRESS,
  INDOPAKET,
  JCB,
  JKO_PAY,
  JNE,
  JT_EXPRESS,
  KERRY_EXPRESS,
  KREDIVO,
  LINE_PAY,
  LION_PARCEL,
  MASTERCARD,
  MULTISYS,
  NINJAVAN,
  OVO,
  PAYLATER_BY_GRAB,
  PAYPAL,
  POS_INDONESIA,
  SAP_EXPRESS,
  SF_EXPRESS,
  SICEPAT_EKSPRES,
  SINGPOST,
  TAISHIN,
  TAQBIN,
  TELKOMSEL,
  UNION_PAY,
  UPS,
  VISA,
  WECHAT_PAY_HK,
  WESTBIKE,
  ZALORA_DELIVERY_EXPRESS,
  ZALORA_HK,
  ZALORA_ID,
  ZALORA_MO,
  ZALORA_MY,
  ZALORA_PH,
  ZALORA_SG,
  ZALORA_TW,
} from 'constants/footer';

export const getCountryLogos = () => {
  return [ZALORA_SG, ZALORA_MY, ZALORA_HK, ZALORA_PH, ZALORA_ID, ZALORA_MO, ZALORA_TW];
};

export const getPaymentLogosByCountry = (countryCode: string | undefined) => {
  switch (countryCode) {
    case 'hk': {
      return [VISA, MASTERCARD, AMERICAN_EXPRESS, UNION_PAY, ALIPAY_HK, WECHAT_PAY_HK];
    }

    case 'id': {
      return [
        MASTERCARD,
        VISA,
        BANK_CENTRAL_ASIA,
        BANK_RAKYAT_ID,
        BANK_NEGARA_ID,
        BANK_MANDIRI,
        JCB,
        CASH_ON_DELIVERY,
        OVO,
        AMERICAN_EXPRESS,
        ATOME,
        GOPAY,
        KREDIVO,
      ];
    }

    case 'my': {
      return [ATOME, MASTERCARD, VISA, AMERICAN_EXPRESS, PAYLATER_BY_GRAB];
    }

    case 'ph': {
      return [MASTERCARD, CASH_ON_DELIVERY, VISA, PAYPAL, JCB, GOOGLE_PAY, MULTISYS];
    }

    case 'sg': {
      return [PAYLATER_BY_GRAB, ATOME, DBS_PAYLAH, MASTERCARD, VISA, AMERICAN_EXPRESS];
    }

    case 'tw': {
      return [LINE_PAY, JKO_PAY];
    }

    default: {
      return [];
    }
  }
};

export const getPreferredCardLogosByCountry = (countryCode: string | undefined) => {
  switch (countryCode) {
    case 'hk': {
      return [DBS, CITIBANK];
    }

    case 'id': {
      return [BANK_CENTRAL_ASIA, GOJEK, BANK_MANDIRI, TELKOMSEL, BANK_NEGARA_ID];
    }

    case 'my': {
      return [CIMB];
    }

    case 'sg': {
      return [DBS];
    }

    case 'tw': {
      return [CTBC, TAISHIN, FUBON];
    }

    default: {
      return [];
    }
  }
};

export const getDeliveryProviderLogosByCountry = (countryCode: string | undefined) => {
  switch (countryCode) {
    case 'hk': {
      return [SF_EXPRESS, KERRY_EXPRESS, UPS];
    }

    case 'id': {
      return [
        JNE,
        LION_PARCEL,
        POS_INDONESIA,
        NINJAVAN,
        SICEPAT_EKSPRES,
        SAP_EXPRESS,
        ZALORA_DELIVERY_EXPRESS,
        WESTBIKE,
        INDOPAKET,
        ID_EXPRESS,
        SF_EXPRESS,
        JT_EXPRESS,
        BLITZ,
      ];
    }

    case 'my': {
      return [GDEX, CITY_LINK, NINJAVAN];
    }

    case 'ph': {
      return [ENTREGO];
    }

    case 'sg': {
      return [TAQBIN, SINGPOST, NINJAVAN, SF_EXPRESS];
    }

    case 'tw': {
      return [SF_EXPRESS, KERRY_EXPRESS, UPS];
    }
    default: {
      return [];
    }
  }
};
