import { ZDTProduct } from '@zalora/doraemon-ts';
import { CheckboxVariant } from '@zalora/zui';
import { TFunction } from 'next-i18next';
import { QueryValue } from 'api/APIClient';
import { ExtendedZDTProductFilterOptions } from 'types/LDT';
import { TActiveCategories, getSubOptionValues } from './data';

export const enum SelectionState {
  NONE,
  PARTIAL,
  ALL,
}

export const checkIsSelected = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
) => {
  if (!option.Value) {
    return false;
  }

  // it is selected when it is in the selected options
  // or all sub options are selected
  return selectedOptions.some(
    (opt) => opt.Value === option.Value || hasSubOptionsInList(opt, [option]),
  );
};

export const hasSubOptionsInList = (
  option: ZDTProduct.FilterOptions | ZDTProduct.FilterSuboption,
  values: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
) => {
  const subOptionValues = getSubOptionValues(option);

  return subOptionValues.some((val) => values.some((option) => option.Value === val));
};

export const deselectSubOptions = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ZDTProduct.FilterOptions,
) => {
  const subOptionsValues = getSubOptionValues(option);

  return selectedOptions.filter((selectedOption) => {
    const selectedOptionValue = selectedOption.Value || '';

    if (selectedOptionValue) {
      return !subOptionsValues.includes(selectedOptionValue);
    }

    return false;
  });
};

export const normalizeCategoryIdParam = (categoryId: QueryValue) => {
  if (!categoryId) {
    return [];
  }

  if (typeof categoryId === 'string') {
    return [categoryId];
  }

  return categoryId as string[];
};

export const getInitialSelection = (filter: ZDTProduct.Filter, subCategoryId: QueryValue) => {
  if (!filter || !filter.Options) {
    return {};
  }

  const splittedSubCatIds = normalizeCategoryIdParam(subCategoryId);

  // Selected category
  const categories = getInitialSelectedCategories(filter, splittedSubCatIds);

  // Selected subcategory
  const selectedOpts = getInitialSelectedOptions(filter.Options, splittedSubCatIds);

  return { selectedOpts, selectedCats: categories };
};

const getInitialSelectedCategories = (
  filter: ZDTProduct.Filter,
  catIds: string[],
): TActiveCategories => {
  if (!filter.Options) {
    return [];
  }

  const categories = filter.Options.filter((option) => {
    if (!option) {
      return false;
    }

    if (option.Value && catIds.includes(option.Value)) {
      return true;
    }

    const subOptionValues = getSubOptionValues(option);

    return subOptionValues.some((val) => catIds.includes(val));
  });

  return categories.map((cat) => {
    const subOptionValues = cat?.SubOptions?.map((opt) => opt.Value);
    const selectedOptionValues = catIds.filter((val) => subOptionValues?.includes(val));

    return {
      Label: cat.Label || '',
      Value: cat.Value || '',
      SelectedOptionValues: selectedOptionValues,
    };
  });
};

export const getInitialSelectedOptions = (
  options: ZDTProduct.FilterOptions[],
  values: string[],
): ZDTProduct.FilterOptions[] => {
  if (!options?.length) {
    return [];
  }

  return options.flatMap((option) => {
    const optionValue = option.Value || '';

    if (optionValue && values.includes(optionValue)) {
      return option;
    }

    if ('SubOptions' in option && option.SubOptions) {
      return getInitialSelectedOptions(option.SubOptions as ZDTProduct.FilterOptions[], values);
    }

    return [];
  });
};

export const getSelectedSubOptions = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
) => {
  if (!option.SubOptions) {
    return [];
  }

  if (checkIsSelected(selectedOptions, option)) {
    return option.SubOptions;
  }

  return option.SubOptions.filter((opt) => {
    const hasCurrentOption = selectedOptions.some((selectedOpt) => selectedOpt.Value === opt.Value);

    return hasCurrentOption || hasSubOptionsInList(opt, selectedOptions);
  });
};

export const getSelectionState = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
): SelectionState => {
  if (!option?.SubOptions?.length) {
    return SelectionState.NONE;
  }

  const selectedSubOptions = getSelectedSubOptions(selectedOptions, option);

  if (
    (option.Value && selectedOptions.some((opt) => opt.Value === option.Value)) ||
    selectedSubOptions.length === option.SubOptions.length
  ) {
    return SelectionState.ALL;
  }

  const hasSelectedSubOption = hasSubOptionsInList(option, selectedOptions);

  if (hasSelectedSubOption || selectedSubOptions.length > 0) {
    return SelectionState.PARTIAL;
  }

  return SelectionState.NONE;
};

export const getOptionLabel = (
  t: TFunction,
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
) => {
  if (!option?.SubOptions) {
    return option.Label;
  }

  const selectionState = getSelectionState(selectedOptions, option);

  switch (selectionState) {
    case SelectionState.NONE: {
      return option.Label;
    }

    case SelectionState.PARTIAL: {
      const selectedSubOptions = getSelectedSubOptions(selectedOptions, option);
      const selectedSubOptionLabels = selectedSubOptions.map((suboption) => suboption.Label);

      return selectedSubOptions.length
        ? `${option.Label} (${selectedSubOptionLabels.join(', ')})`
        : option.Label;
    }

    case SelectionState.ALL: {
      return `${option.Label} (${t('All')})`;
    }
  }
};

export const getCheckboxState = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
): {
  variant: CheckboxVariant;
  checked: boolean;
} => {
  const selectionState = getSelectionState(selectedOptions, option);

  switch (selectionState) {
    case SelectionState.NONE: {
      return {
        checked: false,
        variant: 'primary',
      };
    }

    default: {
      return {
        checked: true,
        variant: 'minus',
      };
    }
  }
};

export const getNewSelectedOptions = (
  selectedOptions: ExtendedZDTProductFilterOptions[] | ZDTProduct.FilterOptions[],
  option: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
  parent?: ExtendedZDTProductFilterOptions | ZDTProduct.FilterOptions,
) => {
  const newSelectedState = !checkIsSelected(selectedOptions, option);
  const isSingleSelect = parent && 'Multi' in parent && !parent?.Multi;
  const siblingOptionValues = parent?.SubOptions?.map((opt) => opt.Value);
  let newSelectedOptions = [...selectedOptions];

  // If single select, deselect other sibling options
  if (newSelectedState && siblingOptionValues && isSingleSelect) {
    newSelectedOptions = newSelectedOptions.filter(
      (opt) => !siblingOptionValues.includes(opt.Value) && opt.Value !== option.Value,
    );
  }

  // Update selection / deselection
  newSelectedOptions = newSelectedState
    ? [...newSelectedOptions, option] // checked
    : newSelectedOptions.filter((opt) => opt.Value !== option.Value); // uncheck

  // If select a option has sub options, deselect all sub options
  // Usecase: when clicking on All option
  if (newSelectedState && option.SubOptions) {
    if (option.SubOptions) {
      newSelectedOptions = deselectSubOptions(newSelectedOptions, option);
    }
  }

  if (!parent) {
    return newSelectedOptions;
  }

  // Handle cases when having parent option which supports All option. 👇🏼

  const selectedSubOptions = getSelectedSubOptions(newSelectedOptions, parent);
  const isSelectedAllSubOptions = selectedSubOptions.length === parent.SubOptions?.length;

  // if select all sub options of parent option,
  // -> deselect all sub options
  // -> select parent instead
  if (newSelectedState && isSelectedAllSubOptions) {
    // remove selected of sub options
    newSelectedOptions = newSelectedOptions.filter(
      (opt) => !selectedSubOptions.some((subopt) => subopt.Value === opt.Value),
    );
    // select parent
    newSelectedOptions = [...newSelectedOptions, parent];
  }

  // if deselect a option under a selected parent option
  // -> deselect parent option, select all remaining sub options
  if (!newSelectedState && isSelectedAllSubOptions && parent.SubOptions) {
    // remove selected of parent option
    newSelectedOptions = newSelectedOptions.filter((opt) => opt.Value !== parent.Value);
    // add all remaining sub options as selected
    newSelectedOptions = [
      ...newSelectedOptions,
      ...(parent.SubOptions.filter(
        (opt) => opt.Value !== option.Value,
      ) as ExtendedZDTProductFilterOptions[]),
    ];
  }

  return newSelectedOptions;
};
