import React from 'react';
import { Card } from 'components/Card';
import { SkeletonItem } from 'components/skeleton';

export const SkeletonFilter = () => {
  return (
    <Card className="flex flex-col gap-4">
      <SkeletonItem width="w-20" />
      <SkeletonItem />
      <SkeletonItem />
    </Card>
  );
};
